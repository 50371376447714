<div class="new-one">
    <div class="card" style="overflow: auto;">
        <h1>Adicionar nova instalação</h1>
        <p>Escolha como deseja prosseguir:</p>
        <div *ngFor="let obj of uniqueDeals">
            <div class="new-action mb-2" (click)="createNewDeal(obj, obj.length == 11 ? 'cpf' : 'cnpj')" *ngIf="obj != null" style="cursor: pointer;">
                <div>
                    <p class="title">Buscar instalações no {{obj.length == 11 ? 'CPF' : 'CNPJ'}} cadastrado</p>
                    <p subtitle="subtitle">{{obj.length == 11 ? (obj | mask: '000.000.000-00') : (obj | mask: '00.000.000/0000-00')}}</p>
                </div>
                <div style="margin-left: auto;">
                    <!-- <span class="fa-regular" style="color: #000 !important;">arrow-right</span> -->
                    <svg style="margin-left: 8px;" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.59375 1.05078L7.34766 1.29688C7.17188 1.47266 7.17188 1.71875 7.34766 1.89453L13.6055 8.15234H0.421875C0.175781 8.15234 0 8.36328 0 8.57422V8.92578C0 9.17188 0.175781 9.34766 0.421875 9.34766H13.6055L7.34766 15.6406C7.17188 15.8164 7.17188 16.0625 7.34766 16.2383L7.59375 16.4844C7.76953 16.6602 8.01562 16.6602 8.19141 16.4844L15.6094 9.06641C15.7852 8.89062 15.7852 8.64453 15.6094 8.46875L8.19141 1.05078C8.01562 0.875 7.76953 0.875 7.59375 1.05078Z" fill="black" />
                    </svg>
                </div>
            </div>
        </div>
        <div class="new-action" (click)="goToNextStep(4)" style="cursor: pointer;">
            <div>
                <p class="title">Adicionar uma instalação de outro CPF ou CNPJ</p>
            </div>
            <div style="margin-left: auto;">
                <!-- <span class="fa-regular" style="color: #000 !important;">arrow-right</span> -->
                <svg style="margin-left: 8px;" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.59375 1.05078L7.34766 1.29688C7.17188 1.47266 7.17188 1.71875 7.34766 1.89453L13.6055 8.15234H0.421875C0.175781 8.15234 0 8.36328 0 8.57422V8.92578C0 9.17188 0.175781 9.34766 0.421875 9.34766H13.6055L7.34766 15.6406C7.17188 15.8164 7.17188 16.0625 7.34766 16.2383L7.59375 16.4844C7.76953 16.6602 8.01562 16.6602 8.19141 16.4844L15.6094 9.06641C15.7852 8.89062 15.7852 8.64453 15.6094 8.46875L8.19141 1.05078C8.01562 0.875 7.76953 0.875 7.59375 1.05078Z" fill="black" />
                </svg>
            </div>
        </div>
        <div class="mt-auto btn-back d-flex justify-content-left ">
            <button style="margin-top: 8px;" (click)="back()"><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.12109 16.4844L9.36719 16.2383C9.54297 16.0625 9.54297 15.8164 9.36719 15.6406L3.10938 9.34766H16.3281C16.5391 9.34766 16.75 9.17188 16.75 8.92578V8.57422C16.75 8.36328 16.5391 8.15234 16.3281 8.15234H3.10938L9.36719 1.89453C9.54297 1.71875 9.54297 1.47266 9.36719 1.29688L9.12109 1.05078C8.94531 0.875 8.69922 0.875 8.52344 1.05078L1.10547 8.46875C0.929688 8.64453 0.929688 8.89062 1.10547 9.06641L8.52344 16.4844C8.69922 16.6602 8.94531 16.6602 9.12109 16.4844Z" fill="#3E4D5B"/>
                </svg>
                Voltar</button>
        </div>
    </div>
</div>

