import { Component, DestroyRef, OnInit, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { ViewIframeService } from 'src/app/services/view-iframe/view-iframe.service';
import { Contact, Deal } from '../services/form.service';
import { WorkingHoursChatService } from '../services/working-hours-chat/working-hours-chat.service';
import { SecondFormComponent } from './second-form/second-form.component';
import { ThirdFormComponent } from './third-form/third-form.component';

@Component({
  selector: 'app-form-stepper',
  templateUrl: './form-stepper.component.html',
  styleUrls: ['./form-stepper.component.scss']
})
export class FormStepperComponent implements OnInit{
  @ViewChild('stepper') stepper!: MatStepper;
  @ViewChild(SecondFormComponent) secondFormComponent!: SecondFormComponent;
  @ViewChild(ThirdFormComponent) thirdFormComponent!: ThirdFormComponent;

  contact: Contact = {};
  deal: Deal = {};
  contactAlreadyExist: boolean = false;
  incompleteDeal: boolean = false;
  progressBarValue: number = 20;
  openPrivacyPolicy: boolean = false;
  urlParams: any;
  emailAndPhoneAlreadyExist: boolean = false;
  phone!: string;
  dealCpfOrCnpjDuplicated: boolean = false;
  viewIsIframe: boolean = false;
  isActiveChatWorkHours: boolean = false;

  private destroyRef = inject(DestroyRef);

  constructor(
    private activatedRoute: ActivatedRoute,
    private viewIframeService: ViewIframeService,
    private workingHoursChatService: WorkingHoursChatService
  ) {
    this.viewIframeService.viewIsIframe$
    .pipe(takeUntilDestroyed())
    .subscribe((value) => {
      this.viewIsIframe = value;
    })

    this.checkIfActive();
  }

  private checkIfActive(): void {
    this.isActiveChatWorkHours = this.workingHoursChatService.getIsActive();
  }

  ngOnInit() {
    this.activatedRoute.queryParams
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe((params) => {
      this.urlParams = {
        hs_facebook_click_id: params["fbclid"] || undefined,
        hs_google_click_id: params["gclid"] || undefined,
        utm_campaign: params["utm_campaign"] || undefined,
        utm_content: params["utm_content"] || undefined,
        utm_medium: params["utm_medium"] || undefined,
        utm_source: params["utm_source"] || undefined,
        utm_term: params["utm_term"] || undefined,
        vantagens: params["vantagens"] || undefined,
        _gl: params["_gl"] || undefined,
        gtm_debug: params["gtm_debug"] || undefined,
        interClickRef: params["interClickRef"] || undefined,
      }
    });
  }

  addValue() {
    this.progressBarValue += 20;
  }

  removeValue() {
    this.progressBarValue -= 20;
  }

  nextStep(event: any) {
    this.contact = event;
    if(event.deal) {
      if(this.contact?.deal?.cpf?.length == null && this.contact?.deal?.cnpj?.length == null){
        this.incompleteDeal = true;
      }
    }

    if(this.stepper.selectedIndex == 0 && !this.emailAndPhoneAlreadyExist) {
      this.secondFormComponent.startCounter();
    }

    this.addValue();
    this.stepper.next();
  }

  receiveDealAndGoToNextStep(event: any) {
    if(event.code == "cpf_or_cnpj_already_exist") {
      this.dealCpfOrCnpjDuplicated = true;
      this.progressBarValue = 100;
    } else {
      this.deal = event;
      this.addValue();
    }
    this.stepper.next();
  }

  receiveDeal(event: any) {
    this.deal = event;
  }

  checkContact(event: any) {
    this.contactAlreadyExist = event;
  }

  checkEmailAndPhone(event: any) {
    this.emailAndPhoneAlreadyExist = event;
    if(this.emailAndPhoneAlreadyExist) {
      this.progressBarValue = 80
    }
  }

  changeSelectedIndex(event: any) {
    this.stepper.previous();
    this.removeValue();
  }

  receivePhone(event: any) {
    this.phone = event;
  }

  privacyPolicy(event: any) {
    this.openPrivacyPolicy = event;
  }

  activeToastNotificationChat() {
    if(this.isActiveChatWorkHours && (!this.contactAlreadyExist || (this.contactAlreadyExist && this.incompleteDeal))) {
      this.thirdFormComponent.openToastNotification(10000);
    }
  }
}
