<div class="card" *ngIf="!updatedDeals">
  <div class="wrapper-section">
    <app-title title="Suas Instalações"></app-title>

    <app-subtitle *ngIf="deals" class="limit-text">
      Abaixo estão as instalações conectadas ao CPF
      <span>{{deals[0]?.cpf! | mask: '000.000.000-00'}}.</span>
      Selecione as instalações que você deseja conectar à Bulbe:
    </app-subtitle>

    <p class="message-error" *ngIf="showErrorMsg">
      Você precisa fornecer uma resposta para cada uma das Instalações.
    </p>

    <div class="wrapper-instalations">
      <div class="box" *ngFor="let deal of deals; let i = index;">
        <div class="column details-instalation">
          <p>{{deal.numero_da_instalacao}}</p>
          <p>{{deal.endereco}}, {{deal.endereco_numero}}, {{deal.endereco_complemento}}</p>
          <p>{{deal.endereco_bairro}} - {{deal.cidade}}/{{deal.estado}} - CEP: {{deal.cep}}</p>
        </div>

        <div class="column">
          <button
            (click)="setConnection(i, true)"
            [ngClass]="{'checked success': deal.connect == true }">
            <app-icons name="check" color="#26D07C"></app-icons>
            Conectar
          </button>

          <button
            (click)="setConnection(i, false)"
            [ngClass]="{'checked cancel': deal.connect == false}">
            <app-icons name="x" color="#EF4444"></app-icons>
            Não Conectar
          </button>
        </div>
      </div>
    </div>

    <app-card-notification
      type="yellow"
      subtitle="As instalações selecionadas serão analisadas pela Bulbe.
        Vamos verificar se elas se enquadram nas nossas
        políticas de uso e, em caso positivo, comunicaremos à Cemig sua adesão.">
    </app-card-notification>
  </div>


  <div class="wrapper-btn">
    <button class="btn primary lg" (click)="onSubmit()" [disabled]="btnIsDisabled">
      Enviar
      <svg width="16" height="17" viewBox="0 0 16 17" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.59375 1.05078L7.34766 1.29688C7.17188 1.47266 7.17188 1.71875 7.34766 1.89453L13.6055 8.15234H0.421875C0.175781 8.15234 0 8.36328 0 8.57422V8.92578C0 9.17188 0.175781 9.34766 0.421875 9.34766H13.6055L7.34766 15.6406C7.17188 15.8164 7.17188 16.0625 7.34766 16.2383L7.59375 16.4844C7.76953 16.6602 8.01562 16.6602 8.19141 16.4844L15.6094 9.06641C15.7852 8.89062 15.7852 8.64453 15.6094 8.46875L8.19141 1.05078C8.01562 0.875 7.76953 0.875 7.59375 1.05078Z"
          fill="white" />
      </svg>
    </button>
  </div>
</div>

<div class="card" *ngIf="updatedDeals" style="height: 211px !important;">
  <div class="wrapper-title">
    <app-icons name="check" color="#26D07C"></app-icons>
    <app-title title="Resposta enviada!"></app-title>
  </div>

  <app-subtitle>
    As instalações selecionadas serão analisadas pela Bulbe. Vamos verificar se elas se enquadram nas nossas políticas
    de uso e, em caso positivo, comunicaremos à Cemig sua adesão.
  </app-subtitle>
</div>
