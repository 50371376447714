<div class="card">
  <div class="wrapper-notification" *ngIf="contactAlreadyExist">
    <div class="notification">
      <span>
        Continue seu cadastro para economizar com a Bulbe.
      </span>
    </div>
  </div>

  <div class="wrapper-title">
    <app-title title="Quase lá!"></app-title>
    <app-subtitle>Você precisa concordar com o Termo de Adesão dos nossos serviços:</app-subtitle>
  </div>

  <app-wrapper-term-policy>
    <h3>Termo de Adesão</h3>

    <p>O presente Termo de Adesão tem como Objeto a adesão, pelo (a) Aderente Locatário(a), ao serviço de geração
      compartilhada, que oferece créditos de desconto de até 15% (quinze por cento) na conta de energia, a ser aplicada na
      tarifa vigente compensável disponibilizada pela Distribuidora de Energia Local, de forma limpa e sustentável.</p>

      <p>O(A) Aderente Locatário(a), pessoa física, já devidamente qualificado(a), declara que possui plenos direitos, capacidade
      civil, poder e autoridade para celebrar o presente Termo de Adesão e, sendo pessoa jurídica, o(a) representante /
      procurador(a) possui poderes para celebrar o presente Termo de Adesão, de acordo com os atos constitutivos da empresa,
      devidamente apresentados, ou procuração outorgada para tal finalidade.</p>

      <p>Após a conclusão do cadastro, o(a) Aderente Locatário(a) concorda em aderir à Cooperativa no papel de Cooperado(a), caso seja pessoa física e, sendo pessoa jurídica, concorda em aderir ao Consórcio, no papel de Consorciado(a). Esta vinculação é condição indispensável para a utilização dos serviços de compensação de energia elétrica, em cumprimento à Lei Federal 14.300 de 6 janeiro de 2022Após a conclusão do cadastro, o(a) Aderente Locatário(a) concorda em aderir à Cooperativa no papel de cooperado(a), caso
      seja pessoa física e, sendo pessoa jurídica, concorda em aderir ao Consórcio, no papel de Consorciado(a). Esta
      vinculação é condição indispensável para a utilização dos serviços de compensação de energia elétrica, em cumprimento à
      Resolução Normativa nº 482, de 17 de abril de 2012, da Agência Nacional de Energia Elétrica – ANEEL e da Lei Federal
      14.300 de 6 janeiro de 2022.</p>

      <p>Ao aderir ao serviço, o(a) Aderente Locatário(a), em conformidade com o art. 684 do Código Civil, outorga procuração, de
      forma irrevogável e irretratável, com poderes gerais à Cooperativa se pessoa física e, ao Consórcio, se pessoa jurídica,
      na pessoa de seus respectivos representantes legais para: (i) representação nas deliberações e votações em assembleia da
      Cooperativa, com poderes especiais para o recebimento de citações, intimações e notificações provenientes de qualquer
      processo judicial e/ou administrativo por força da sua condição de Cooperado(a) e da adesão aos Termos e Condições de
      Uso e da Política de Privacidade; (ii) representação perante à Concessionária Local, ANEEL e quaisquer terceiros, sejam
      entes da administração pública ou privados para (a) realizar quaisquer ações necessárias, podendo assinar instrumentos e
      acordos, ceder, transferir, transigir e renunciar a direitos, referente a sua participação no Sistema de Compensação de
      Energia Elétrica (SCEE), com relação à(s) sua(s) unidade(s) consumidora(s), incluindo, mas não se limitando a (a)
      alterar formas de recebimento de fatura; (b) solicitar 2ª (segunda) via de fatura; (c) gerir a conta de consumo de
      energia e da cota contratada, podendo alocar o percentual de créditos destinados segundo o perfil de consumo e alterar o
      valor e/ou quantidade de sua cota, bem como realização de eventual troca de titularidade, (iii) substabelecer, com ou
      sem reserva de poderes; (iv) inclusão, exclusão, eliminação ou demissão do(a) Aderente Locatário(a) perante a
      Cooperativa nos termos do Estatuto da Cooperativa; (v) em se tratando de matérias relacionadas e inerentes ao Consórcio,
      a) comparecer e exercer o direito de voto em quaisquer matérias em todas as futuras reuniões e/ ou deliberações do
      Consórcio; (b) celebrar todas as futuras Alterações do Instrumento de Consórcio, inclusive para o seu encerramento; (c)
      transferir as participações detidas no Consórcio e dar quitação com relação a estas transferências; (d) ceder ou
      transferir a sua participação no Consórcio, inclusive dando quitação sobre essas respectivas cessões ou transferências;
      (e) assinar todos os documentos do Consórcio referentes a transferências de participações; (f) assinar física ou
      digitalmente por meio de certificação digital quaisquer requerimentos, capas de processo, formulários de Documento
      Básico de Entrada (DBE), Fichas de Cadastro Nacional (FCN) e atos, ou documentos necessários à efetivação dos atos a
      serem apresentados a arquivamento na Junta Comercial Competente e na Receita Federal do Brasil – RFB; (g) representar o
      Aderente Locatário perante a CIA ENERGÉTICA DE MINAS GERAIS S.A., inscrita no CNPJ/ ME sob nº 17.155.730/0001-54, e
      quaisquer de suas empresas controladas, em especial a CEMIG DISTRIBUIÇÃO S.A., inscrita no CNPJ/ME sob nº
      06.981.180/0001-16, ambas com sede na Av. Barbacena, nº 1200, Santo Agostinho, Belo Horizonte/ MG, CEP 30190-131 (em
      conjunto, a “Distribuidora”) ou qualquer outra Concessionária de Energia, para solicitar acesso e/ ou pedidos de
      alteração de cadastro e de endereço de cobrança, inclusive por meios digitais que requeiram login e senha, solicitar
      alterações ou adequações na conta de energia elétrica, solicitar e assinar todos e quaisquer documentos relacionados a
      eventuais transferências de percentual de participação no Consórcio, além de representar o (a) Aderente Locatário(a)
      para realizar a gestão da unidade consumidora em seu nome perante a Distribuidora, inclusive para fins de apresentação
      de reclamações; e, (vi) enfim, todos os atos e diligências especificamente e exclusivamente, que se façam necessários
      para o bom funcionamento da Cooperativa, do Consórcio e/ou de qualquer outro instituto jurídico autorizado e reconhecido
      pelas entidades competentes, podendo ser representado (a) de forma isolada, bem como substabelecer, sem reservas de
      poderes, o presente Termo de Adesão, para o fiel cumprimento deste mandato.</p>

      <p>O(A) Aderente Locatário(a) tem ciência que poderá receber uma quantidade de créditos maior que seu consumo histórico,
      independente de recebimento de comunicação prévia por parte da Cooperativa ou do Consórcio.</p>

      <p>O(A) Aderente Locatário(a) poderá solicitar a rescisão do presente Termo de Adesão a qualquer tempo, desde que
      devidamente cumpridas as condições constantes do Termos e Condições de Uso. O(A) Aderente Locatário(a) tem ciência que
      continuará responsável pelo pagamento dos créditos já injetados em sua unidade consumidora, até o momento em que eles
      sejam totalmente consumidos ou pagos (“Saldo de Créditos”), independente da data da comunicação do pedido de rescisão.
      Em caso de falecimento do Aderente/Locatário, o espólio ficará responsável pelo pagamento de todos os débitos,
      incluindo, mas, sem se limitar, a faturas não pagas e o Saldo de Créditos, respeitado o disposto no artigo 1.997 do
      Código Civil Brasileiro e no artigo 796 do Código de Processo Civil.</p>

      <p>A Cooperativa declara que praticará todas as medidas necessárias para que este Termo de Adesão, celebrado pelo(a)
      Aderente Locatário (a) sob seu conhecimento, seja registrado em sua sede no respectivo Livro de matrícula ou, se
      tratando do Consórcio, que este Termo de Adesão será registrado perante a Junta Comercial competente.</p>

      <p>O(A) Aderente Locatário(a) declara que, mediante assinatura do presente Termo de Adesão, está ciente e de acordo com os
      Termos e Condições de Uso e com a Política de Privacidade constantes em nossa plataforma.</p>

      <hr>

      <p>¹ A percepção de benefício auferido com o aluguel das cotas da usina solar é de até 15% (quinze por cento) de
      economia e leva em consideração a tarifa vigente compensável disponibilizada pela CEMIG, sem a incidência de qualquer
      tributo. O(A) Consórcio/Cooperativa terá plena autonomia para injetar na(s) instalação(ões) do(a) Aderente Locatário(a)
      a quantidade de Créditos de Energia que considerar apropriado, correspondente à quantidade de módulos locados.</p>

      <section>
          <h4>Termos e condições gerais de uso</h4>
      </section>

      <p>Ao realizar a adesão da Bulbe Energia Cooperativa (“Bulbe” ou “Bulbe Energia”), o
        Cooperado/Locatário anui aos termos aqui dispostos e concorda em se submeter
        integralmente às condições mencionadas a seguir.
        </p>

      <p><strong>1. Informações Preliminares</strong></p>

      <p>Ao aderir, através do Site ou do APP, o Cooperado/Locatário concorda com os Termos de Uso, Termo de adesão e Política de
      Privacidade do Site e do APP.</p>

      <p><strong>2. Do ingresso a Cooperativa</strong></p>

      <p>A adesão ao Sistema de Compensação de Créditos de Energia da Bulbe implica o ingresso do Cooperado/Locatário na Bulbe
      Energia Cooperativa, na condição de Cooperado/Locatário, por imposição da Lei Federal nº 14.300 de 06 de janeiro de
      2022.</p>

      <p><strong>3. Do início do serviço</strong></p>

      <p>Aprovada a adesão, o Cooperado/Locatário ingresso na Bulbe Energia Cooperativa. A partir daí, a Bulbe assume a
      comunicação com a Distribuidora local, informando à mesma que o Cooperado/Locatário agora faz parte do Sistema de
      Compensação de Energia Elétrica.</p>

      <p>Mensalmente a Bulbe comunica à Distribuidora local o percentual de créditos de energia que será destinado à instalação
      de cada um de seus Cooperados/Locatários. O prazo regulatório para a Distribuidora processar a alocação dos créditos de
      energia é de até 30 (trinta) dias. Havendo intercorrências no processo, este prazo poderá ser estendido. Vale ressaltar
      que, durante o referido período, o pagamento da fatura CEMIG da instalação cadastrada continua sendo devido.</p>

      <p>O benefício atribuído à unidade consumidora do Cooperado/Locatário será aplicado sobre as componentes da tarifa de
      energia elétrica vigente e sobre os tributos passíveis de compensação, inclusive eventuais acréscimos da bandeira
      tarifária vigente.</p>

      <p>A cobrança da Contribuição de Iluminação Pública (CIP) e do PIS/Cofins não pode ser compensada com a injeção de créditos
      de energia. A CIP e o PIS/Cofins são tributos devidos aos entes públicos, recolhidos pela Distribuidora local na fatura
      de luz e repassados em sua integralidade para o Município e a União.</p>

      <p>O custo de disponibilidade¹ também não pode ser compensado com créditos de energia. Somente a energia elétrica
      efetivamente consumida pelo Cooperado/Locatário, acrescida do ICMS aplicável, será compensada com a energia solar gerada
      pela Bulbe.</p>

      <p>Além da alocação de créditos de energia para a instalação de titularidade do Cooperado/Locatário, a Bulbe realiza a
      intermediação de sua relação com a Distribuidora de energia local.</p>

      <p>A sistemática deste serviço ocorre da seguinte forma: (i) a Bulbe emitirá uma Fatura Única ao Cooperado/Locatário,
      indicando os valores devidos à Bulbe Energia Cooperativa pela alocação dos créditos de energia devidos à Distribuidora
      pelo serviço de distribuição da energia; (ii) o Cooperado/Locatário deve realizar o pagamento da Fatura Única
      diretamente para a Bulbe que, por sua vez, repassará o valor devido à Distribuidora. O repasse à Distribuidora só
      ocorrerá após pagamento da Fatura Única.</p>

      <p>Para garantir a eficiência na prestação do serviço pela Bulbe, o Cooperado/Locatário deverá interromper os pagamentos
      das faturas da Distribuidora que cheguem em sua residência após o início do recebimento dos créditos de energia,
      inclusive retirando do débito automático caso assim esteja. O Cooperado/Locatário será informado do início do
      recebimento dos créditos em sua unidade consumidora, momento a partir do qual deverá deixar de pagar à Distribuidora e
      pagar somente a Fatura Única da Bulbe.</p>

      <p>A Bulbe não possui qualquer responsabilidade ou gerência sobre as faturas que estiverem em inadimplidas, referente a
      períodos anteriores ao início da compensação de energia.</p>

      <p>Em caso de inadimplemento da Fatura Única pelo Cooperado/Locatário, as novas alocações de créditos de energia e de
      intermediação com a Distribuidora poderão ser imediatamente suspensas. A Bulbe responderá tão somente pela quitação
      junto à Distribuidora do Valor de Repasse efetivamente pago pelo Cooperado/Locatário, não respondendo, em nenhuma
      hipótese, por eventuais diferenças que venham a ser cobradas pela Distribuidora em razão da mora do Cooperado/Locatário.</p>

      <p>Para a emissão da Fatura Única, o Cooperado/Locatário autoriza que a Bulbe utilize seus dados para consultar informações
      pertinentes ao consumo de energia elétrica e seus dados cadastrais junto à Distribuidora, sempre com observância à Lei
      Geral de Proteção de Dados.</p>

      <p>O Cooperado/Locatário reconhece que o serviço de distribuição de energia elétrica é de responsabilidade da Distribuidora
      local. Eventual falha na prestação de serviços pela Distribuidora não poderá implicar, de maneira alguma, em
      reconhecimento de mora ou responsabilização da Bulbe.</p>

      <p>A Bulbe não realiza cobrança de taxas de adesão nem mensalidade pelo serviço de intermediação junto à Distribuidora.</p>

      <p><strong>4. Do Pagamento</strong></p>

      <p>Ao fim de cada ciclo de faturamento da Distribuidora, será medido o consumo de energia do Cooperado/Locatário e enviada
      a Fatura Única, de forma 100% digital, para os meios de comunicação cadastrados. Além de ser enviada, o
      Cooperado/Locatário conseguirá ter acesso às faturas disponíveis para pagamento no APP da Bulbe.</p>

      <p>O pagamento da fatura poderá ser feito via boleto, débito direto autorizado (DDA) ou PIX.</p>

      <p>A opção pelo pagamento via DDA, dada a sensibilidade das informações e seu vínculo bancário, requer que seja acompanhada
      pelo aceite do Termo de Autorização disponibilizado.</p>

      <p>Quaisquer valores cobrados ou estornados exclusivamente pela Distribuidora, como tarifas, taxas, contribuições, multas e
      bonificações, serão repassados integralmente ao Cooperado/Locatário. Além disso, eventuais doações ou contribuições para
      terceiros, pagas habitualmente através da fatura da Distribuidora de energia, continuarão ocorrendo normalmente por meio
      da Fatura Única.</p>

      <p>Em caso de falecimento do Cooperado/Locatário e, se houverem débitos em aberto junto à Bulbe, incluindo, mas, sem se
      limitar, a faturas não pagas e os créditos de energia alocados ou saldo acumulado à geração de energia da Bulbe (“Saldo
      de Créditos”), entre outros, serão cobrados do espólio do Cooperado/Locatário, respeitado o disposto no artigo 1.997 do
      Código Civil Brasileiro e no artigo 796 do Código de Processo Civil.</p>

      <p><strong>5. Atrasos e multas</strong></p>

      <p>Caso não seja identificado o pagamento da Fatura Única após o vencimento, a Bulbe poderá iniciar o processo de cobrança
      via contato por aplicativo de mensagens, SMS, ligação e e-mail.
      O pagamento em atraso poderá acarretar a cobrança de multa de 2% (dois por cento) do valor total da fatura, juros
      diários de 1% e correção monetária pelo IPCA, proporcional ao período que o Cooperado/Locatário permanecer em atraso.</p>

      <p>Na hipótese de não pagamento em até 15 (quinze) dias após a data de vencimento da fatura, a Bulbe poderá suspender a
      alocação de créditos de energia e efetivar o protesto do título, além de inscrever o Cooperado/Locatário perante os
      órgãos competentes.</p>

      <p>A política de cobrança poderá sofrer alterações a qualquer momento sem que haja necessidade de aviso prévio.</p>

      <p><strong>6. Dos procedimentos de Cancelamento, Suspensão e Exclusão Cancelamento</strong></p>

      <p>O Cooperado/Locatário poderá efetuar o cancelamento do serviço a qualquer momento, sem incidência de multa ou
      penalidade. A solicitação poderá ser feita via e-mail, WhatsApp ou ligação para o time de suporte da Bulbe. Os meios de
      contato estão disponíveis no Site da Bulbe (<a href="https://bulbeenergia.com.br">https://bulbeenergia.com.br</a>).</p>

      <p>A partir do momento em que o Cooperado/Locatário solicita o cancelamento, a Bulbe informa à Distribuidora para não mais
      destinar novos créditos de energia para a instalação cadastrada. Os créditos já alocados, contudo, continuarão sendo
      objeto de faturamento com a emissão da Fatura Única mês a mês, até serem esgotados e completamente quitados.</p>

      <p>O Cooperado/Locatário poderá optar por realizar o pagamento de todos os créditos acumulados em um boleto único ou
      utilizá-los na mesma instalação ou sob a mesma titularidade em 60 (sessenta) meses.</p>

      <p>O Cancelamento se concretiza assim que toda e qualquer crédito de energia alocado ou saldo acumulado à geração de
      energia da Bulbe (“Saldo de Créditos”) estiverem liquidados e quitados pelo Cooperado/Locatário. Com isso, será
      restaurado o envio da sua fatura de energia pela Distribuidora local.</p>

      <p><strong>7. Mudança de Endereço</strong></p>

      <p>Em caso de mudança de endereço, o Cooperado/Locatário deverá comunicar à Bulbe através dos nossos canais de atendimento,
      com no mínimo 90 (noventa) dias de antecedência.</p>

      <p>Se a unidade consumidora possuir Saldo de Créditos, estes serão alocados para o novo endereço sob a mesma titularidade
      através do formulário de cadastro disponibilizado pela Distribuidora local.</p>

      <p><strong>8. Troca de titularidade</strong></p>

      <p>O Cooperado/Locatário deve comunicar à Bulbe em caso de troca de titularidade da unidade consumidora, através dos nossos
      canais de atendimento, com no mínimo 90 (noventa) dias de antecedência.</p>

      <p>A Bulbe irá coletar os dados do novo titular e informar à Distribuidora. A alocação dos créditos de energia após a
      mudança de titularidade estará sujeita ao prazo regulatório de 30 (trinta) dias. Havendo intercorrências no processo,
      este prazo poderá ser estendido.</p>

      <p><strong>9. Das Responsabilidades do Cooperado/Locatário</strong></p>

      <p>É de responsabilidade do Cooperado/Locatário:</p>

      <p>a) Efetuar os pagamentos da Fatura Única dentro do prazo de vencimento;</p>
      <p>b) Cumprir todas as condições estabelecidas nestes Termos e Condições, na Política de Privacidade e no Termo de Adesão;</p>
      <p>c) Manter os dados cadastrais atualizados, informando em caso de alteração de endereço, troca de titularidade ou desligamento de instalação;</p>
      <p>d) Não prestar informações falsas ou incompletas;</p>
      <p>e) Não utilizar ou se apropriar da marca, logotipo, serviços, programas e funcionalidades da plataforma sem consentimento prévio e por escrito da Bulbe.</p>

      <p><strong>10. Das Responsabilidades da Bulbe</strong></p>

      <p>É de responsabilidade da Bulbe:</p>

      <p>a) Efetuar o repasse do pagamento devido à Distribuidora imediatamente após a identificação do pagamento da Fatura Única;</p>
      <p>b) Manter os canais de atendimento ao Cooperado/Locatário disponíveis das 8h às 16h;</p>
      <p>c) Envidar os melhores esforços para que o atendimento seja cordial e eficiente.</p>

      <p><strong>11. Das Responsabilidades da Distribuidora de Energia</strong></p>

      <p>É de responsabilidade da Distribuidora:</p>

      <p>a) Realizar a leitura do consumo mensal da(s) instalação(es) de titularidade do Cooperado/Locatário;</p>
      <p>b) Disponibilizar os dados do faturamento do Cooperado/Locatário para a Bulbe;</p>
      <p>c) Realizar todas as manutenções na rede elétrica;</p>
      <p>d) Realizar corte ou religação e levar a energia até as unidades consumidoras dentro de sua área de concessão, conforme resolução normativa da ANEEL n° 1.000/21.</p>

      <p><strong>12. Sigilo e Privacidade</strong></p>

      <p>A Bulbe respeita todas as normas e diretrizes prevista na Lei Geral de Proteção de Dados – LGPD (Lei Federal n.º 13.709/2018), conforme estabelecido em sua política de privacidade.</p>

      <p>Quando da adesão ao presente serviço, o Cooperado/Locatário expressamente consente com o tratamento e a transferência de
      seus dados relativos a consumo de energia elétrica, faturamento e pagamentos realizados, dentre outros de mesma natureza
      e que sejam pertinentes à finalidade da Bulbe Energia Cooperativa, ao cumprimento da Lei nº 14.300/2022, das Resoluções
      Normativas da Agência Nacional de Energia Elétrica (“ANEEL”) nº 1.059/23 e 1.000/21, e qualquer regimento ou norma
      aplicável às atividades econômicas ou relacionadas à Bulbe Energia Cooperativa. O Cooperado/Locatário concorda ainda que
      nada disposto no presente Termos e Condições, viola seus direitos previstos na Lei Geral de Proteção de Dados.</p>

      <p><strong>13. Disposições Gerais</strong></p>

      <p>Os Termos e Condições ora previstos e quaisquer outras políticas divulgadas representam o pleno e completo acordo e
      entendimento entre o Ciente e a Bulbe, estando afastadas quaisquer disposições em sentido contrário.</p>

      <p>Estes Termos e Condições não geram nenhum contrato de sociedade, franquia ou relação de trabalho entre o
      Cooperado/Locatário e a Bulbe.</p>

      <p>Na hipótese de qualquer termo ou disposição do presente instrumento vir a ser declarado nulo ou não aplicável, tal
      nulidade ou inexequibilidade não afetará quaisquer outros termos ou disposições aqui contidas, os quais permanecerão em
      pleno vigor e efeito.</p>

      <p>Eventual tolerância relacionada a infrações ou descumprimento das condições estipuladas no presente instrumento será
      tida como ato de mera liberalidade, não constituindo precedente, novação ou renúncia a direitos que a legislação ou o
      contrato assegurem.</p>

      <p>A Bulbe poderá alterar, a qualquer tempo, estes Termos e Condições, visando seu aprimoramento e a melhoria dos serviços prestados.</p>

      <p><strong>14. Legislação aplicável e Foro de eleição</strong></p>

      <p>Todos os itens destes Termos e Condições são regidos pelas leis vigentes na República Federativa do Brasil.</p>

      <p>Para dirimir toda e qualquer dúvida relacionada ao cumprimento do presente instrumento ou qualquer outro questionamento
      pertinente ao mesmo, o Cooperado/Locatário concorda com a jurisdição exclusiva do foro de Belo Horizonte/MG.</p>

      <hr>

      <p>¹ Essa taxa mínima de energia, também conhecida, como custo de disponibilidade, é o valor cobrado pela CEMIG para disponibilizar a eletricidade. Ela inclui, portanto, os  custos de toda a infraestrutura elétrica para garantir a eletricidade aos moradores do estado de Minas Gerais. Indicado como “ENERGIA ELÉTRICA” em sua fatura CEMIG.</p>
      <p>² Os créditos de energia alocados na instalação cadastrada estarão indicados como “ENERGIA COMPENSADA GD” na fatura CEMIG.</p>
      <p>³ Os créditos de energia previamente alocados continuam sendo devidos e objeto de faturamento.</p>
  </app-wrapper-term-policy>

  <form [formGroup]="fourthForm">
    <mat-checkbox formControlName="checkbox" id="checkbox">
      Li e aceito os <u>Termos de Adesão</u> da Bulbe.
    </mat-checkbox>
  </form>

  <div class="wrapper-btn">
    <button class="btn primary lg" id="button-aceite" [disabled]="!fourthForm.get('checkbox')!.value" (click)="onSubmit()">
      {{ textBtn }}
      <app-icons name="arrow-right" color="#FFF"></app-icons>
    </button>
  </div>
</div>

<div class="wrapper-btn-help" *ngIf="showToastNotificationChat">
  <button class="btn primary xl" (click)="openToastNotification()">
    <app-icons name="?" color="#FFF"></app-icons>
  </button>
</div>

<ng-template #customToast>
  <div class="toast-notification-card-header">
    <img src="../../../assets/images/user-agent-wpp.png" alt="">
    <h3>Precisa de ajuda?</h3>
  </div>
  <div class="toast-notification-card-content">
    <p>Clique e fale com nosso time de consultores via WhatsApp.</p>
  </div>
  <div class="toast-notification-card-footer">
    <button class="btn primary xs" (click)="openChatWpp()">
      <app-icons name="whatsapp" color="#FFF"></app-icons>
      Tirar dúvidas
    </button>
  </div>
</ng-template>
