import { Injectable, TemplateRef } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

export enum ToastNotificationTypes{
  ERROR = "error",
  WARNING = "warning",
  SUCCESS = "success",
  INFO = "info"
}

export interface Toast {
  id?: string;
  show: boolean;
  type: 'success' | 'error' | 'info' | 'warning';
  borderStyle: "top" | "left";
  title?: string;
  text?: string;
  template?: TemplateRef<any>;
  duration: number; // Em milissegundos
}

@Injectable({
  providedIn: "root"
})
export class ToastNotificationService {

  private toastsSubject = new BehaviorSubject<Toast[]>([]);
  toasts$ = this.toastsSubject.asObservable();

  private generateId(): string {
    return Math.random().toString(36).substring(2, 9);
  }

  show({ type , title, text, duration = 0, borderStyle }: Omit<Toast, "id" | "template" | "show">) {
    const id = this.generateId();
    const toastTemp: Toast = { id, type, title, duration, text, borderStyle, show: true };

    this.toastsSubject.next([...this.toastsSubject.value, toastTemp]);

    if (duration > 0) {
      setTimeout(() => this.dismiss(id), duration);
    }
  }

  showTemplate({ id = "",  type , template, duration = 0, borderStyle }: Omit<Toast, "title" | "text" | "show">) {
    const idGenerate = this.generateId();

    const isCurrentToastOpen = this.toastsSubject.value.find(t => t.id === id);

    if (isCurrentToastOpen) return;

    const currentId = id || idGenerate;
    const toastTemp: Toast = { id: currentId, type, template, duration, borderStyle, show: true};

    this.toastsSubject.next([...this.toastsSubject.value, toastTemp]);

    if (duration > 0) {
      setTimeout(() => this.dismiss(currentId), duration);
    }
  }

  dismiss(id: string) {
    const toastMap = this.toastsSubject.value.map((toast: Toast) => {
      if(toast.id === id) toast.show = false
      return toast
    })

    this.toastsSubject.next(toastMap);
      setTimeout(() => {
        this.toastsSubject.next(this.toastsSubject.value.filter(t => t.id !== id));
      }, 50);
  }
}
