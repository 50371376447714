export function formatFileSize(bytes: number) {
  if(bytes == 0) return "0 Bytes";

  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(1000));

  const result = parseFloat((bytes / Math.pow(1000, i)).toFixed(2));

  return `${result} ${sizes[i]}`; // 1.0MB
}
