import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Contact, Deal, FormService } from 'src/app/services/form.service';

@Component({
  selector: 'app-choose-new-installation',
  templateUrl: './choose-new-installation.component.html',
  styleUrls: ['./choose-new-installation.component.scss']
})
export class ChooseNewInstallationComponent implements OnInit{

  @Output() nextStep2 = new EventEmitter<number>();
  @Output() previousStep2 = new EventEmitter<number>();
  @Input() contact!: Contact;
  hideButton: boolean = false;
  uniqueDeals: any = [];

  private destroyRef = inject(DestroyRef);

  constructor(private formService: FormService) {}

  ngOnInit(): void {
    this.getUser();
  }

  getUser() {
    this.formService.getUser(this.contact.email!, "email").pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: (response: any) => {
        if(response.contact.aceite_do_termo_de_adesao == "true") {
          this.getDeals();
        } else {

        }
      }
    })
  }

  goToNextStep(step: number) {
    this.nextStep2.next(step);
  }

  getDeals() {
    this.formService.getAllDeals(this.contact.id!).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: (response: any) => {
        if(response.deals) {
          this.uniqueDeals = this.findUnique(response.deals);
        }
      }
    })
  }

  findUnique(arr: any) {
    const cpfs: any = {};
    const uniqueData = [];

    const cnpjs: any = {};

    for (const obj of arr) {
      if (obj.cpf != null) {
        const cpf = obj.cpf;
        if (!cpfs[cpf]) {
          cpfs[cpf] = true;
          uniqueData.push(cpf);
        }
      } else if(obj.cnpj != null) {
        const cnpj = obj.cnpj;
        if(!cnpjs[cnpj]) {
          cnpjs[cnpj] = true;
          uniqueData.push(cnpj);
        }

      }
    }

    return uniqueData;
  }

  createNewDeal(value: string, type: string) {
    const payload: Deal = {
      contact_id: this.contact.id,
      deal_stage: "1780601",
      deal_name: type == "cpf" ? `${this.contact.firstname} ${this.contact.lastname ? this.contact.lastname : ""} - CPF: ${value}` : `${this.contact.firstname} ${this.contact.lastname} - CNPJ: ${value}`,
      cpf: type == "cpf" ? value : null,
      cnpj: type == "cnpj" ? value : null
    }

    this.formService.createNewDeal(payload).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: (response: Deal) => {
        this.goToNextStep(3);
      }
    })
  }

  back() {
    this.previousStep2.next(0);
  }
}
