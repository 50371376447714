import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormService } from 'src/app/services/form.service';

@Component({
  selector: 'app-select-installarions',
  templateUrl: './select-installarions.component.html',
  styleUrls: ['./select-installarions.component.scss']
})
export class SelectInstallarionsComponent implements OnInit {

  @Input() contact_id?: string;
  @Output() sendUpdatedDeals = new EventEmitter<boolean>();

  showErrorMsg: boolean = false;
  updatedDeals: boolean = false;
  btnIsDisabled: boolean = true;
  decryptedContactId!: string;

  private destroyRef = inject(DestroyRef);
  deals: any;

  constructor(private formService: FormService) {}

  ngOnInit(): void {
    this.decryptedContactId = this.decryptContact();
    this.getMultipleInstallations();
  }

  decryptContact() {
    const partnershipCode = parseInt(this.contact_id!.substring(0, 2), 16);
    const ecryptedCodeId = parseInt(this.contact_id!.substring(2, this.contact_id!.length), 16);
    const n = ecryptedCodeId - partnershipCode - 452;
    return n.toString();
  }

  getMultipleInstallations() {
    this.formService.getAllContactInstallations({ contact_id: this.decryptedContactId! }).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: (response: any) => {
        this.deals = response.deals;
      }
    })
  }

  setConnection(index: number, value: boolean) {
    this.showErrorMsg = false;
    this.btnIsDisabled = false;
    this.deals[index].connect = value;
  }

  onSubmit() {
    this.showErrorMsg = false;
    let error = 0;
    const payload = this.deals.map((e: any) => {
      return {
        deal_id: e.id,
        connect: e.connect
      }
    });
    payload.forEach((e: any) => {
      if (e.connect != true && e.connect != false) {
        error++;
      }
    });
    if (error > 0) {
      this.showErrorMsg = true;
      return
    }
    this.formService.updateDeals(payload).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: (response) => {
        this.updatedDeals = true;
        this.sendUpdatedDeals.next(true);
      }
    })
  }

}
