import { Component, DestroyRef, inject, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ViewIframeService } from 'src/app/services/view-iframe/view-iframe.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  @Input() showBtnCloseModal: boolean = false;
  showModal: boolean = false;
  isViewIframe: boolean = false;

  private destroyRef = inject(DestroyRef);

  constructor(private modalService: ModalService, private viewIframeService: ViewIframeService) {
    this.modalService.showModal$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((show) =>
        this.showModal = show
    );

    this.viewIframeService.viewIsIframe$
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe((isFrame) =>
      this.isViewIframe = isFrame
    );
  }

  ngOnChanges(): void {
    this.modalService.showModal$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((show) => this.showModal = show);
  }

  closeModal() {
    this.modalService.close();
  }

  handleKeyDown(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.modalService.close();
    }
  }
}
