<div class="wrapper-footer">
  <div class="content">
    <p>Baixe o Bulbe APP para acessar a<br /> <strong>Área do Cliente. </strong></p>

    <div class="wrapper-img">
      <button (click)="openApp(1)">
        <img src="../../../assets/images/app.svg" />
      </button>

      <button (click)="openApp(2)">
        <img src="../../../assets/images/gplay.png" />
      </button>
    </div>
  </div>
</div>
