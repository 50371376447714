import { Component } from '@angular/core';

@Component({
  selector: 'app-wrapper-term-policy',
  templateUrl: './wrapper-term-policy.component.html',
  styleUrls: ['./wrapper-term-policy.component.scss']
})
export class WrapperTermPolicyComponent {

}
