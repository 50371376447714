import { ToastNotificationService, ToastNotificationTypes } from 'src/app/services/toast-notification/toast-notification.service';
import { Component, DestroyRef, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as cpfCnpjValidator from 'cpf-cnpj-validator';
import { Contact, Deal, FormService } from 'src/app/services/form.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ViewIframeService } from 'src/app/services/view-iframe/view-iframe.service';

@Component({
  selector: 'app-third-form',
  templateUrl: './third-form.component.html',
  styleUrls: ['./third-form.component.scss']
})
export class ThirdFormComponent implements OnInit {
  @ViewChild('customToast', { static: true }) customToast!: TemplateRef<any>;

  @Input() cpfNotFound: boolean = false;
  @Input() urlParams: any;
  @Input() contact!: Contact;
  @Input() deal!: Deal;
  @Input() contactAlreadyExist!: boolean;
  @Input() incompleteDeal!: boolean;
  @Input() showToastNotificationChat: boolean = false;
  @Output() nextStep = new EventEmitter<Contact>();
  @Output() nextStep4 = new EventEmitter<Contact>();
  @ViewChild('thirdSubmitButton', { static: true }) thirdSubmitButton!: ElementRef;

  showCpfError: boolean = false;
  showMyCpfError: boolean = false;
  showCpfEqualError: boolean = false;
  showCnpjError: boolean = false;

  idChatNotification: string = "55D5D1D8D1F8";
  typeToasNotificationWpp: ToastNotificationTypes = ToastNotificationTypes.INFO
  showMyCpfErrorAlreadyExist: boolean = false;
  viewIsIframe: boolean = false;

  private destroyRef = inject(DestroyRef);

  constructor(private formService: FormService,
    private modalService: ModalService,
    private viewIframeService: ViewIframeService,
    private toastNotificationService: ToastNotificationService
  ) {
    this.thirdSubmitButton = new ElementRef(null);

    this.viewIframeService.viewIsIframe$
    .pipe(takeUntilDestroyed())
    .subscribe((value) => {
      this.viewIsIframe = value;
    })
  }

  thirdForm = new FormGroup({
    checkbox1: new FormControl(false, Validators.required),
    checkbox2: new FormControl(false, Validators.required),
    checkbox3: new FormControl(false),
    myCpf: new FormControl(null),
    cpf: new FormControl(null),
    cnpj: new FormControl(null)
  });

  ngOnInit(): void {
    this.thirdForm.get('checkbox1')!.valueChanges.subscribe((value) => {
      if (value) {
        this.thirdForm.controls['cpf'].setValidators([Validators.required]);
      } else {
        this.thirdForm.controls['cpf'].clearValidators();
        this.thirdForm.get('myCpf')!.setValue(null);
        this.thirdForm.get('cpf')!.setValue(null);
      }
    });

    this.thirdForm.get('checkbox2')!.valueChanges.subscribe((value) => {
      if (value) {
        this.thirdForm.controls['cnpj'].setValidators([Validators.required]);
      } else {
        this.thirdForm.controls['cnpj'].clearValidators();
        this.thirdForm.get('cnpj')!.setValue(null);
      }
    });

    this.thirdForm.get('checkbox3')!.valueChanges.subscribe((value) => {
      if (value) {
        this.thirdForm.get('cpf')!.setValue(this.thirdForm.get('myCpf')!.value);
        this.thirdForm.controls['cpf'].disable();
        document.querySelector("#document-input-label")!.classList.add('disabled');
        this.showCpfEqualError = false;
      } else {
        this.thirdForm.get('cpf')!.setValue(null);
        this.thirdForm.controls['cpf'].enable();
        document.querySelector("#document-input-label")!.classList.remove('disabled');
      }
    });

    this.thirdForm.get('checkbox1')!.valueChanges.subscribe(this.validateDocumentsInputs.bind(this));
    this.thirdForm.get('checkbox2')!.valueChanges.subscribe(this.validateDocumentsInputs.bind(this));
    this.thirdForm.get('cpf')!.valueChanges.subscribe(this.validateDocumentsInputs.bind(this));
    this.thirdForm.get('cnpj')!.valueChanges.subscribe(this.validateDocumentsInputs.bind(this));
    this.thirdForm.get('myCpf')!.valueChanges.subscribe(this.validateDocumentsInputs.bind(this));

    this.thirdForm.get('myCpf')!.valueChanges.subscribe((value: any) => {
      this.showMyCpfErrorAlreadyExist = false;
      this.showMyCpfError = false;

      if (this.thirdForm.get('checkbox3')!.value) this.thirdForm.get('cpf')!.setValue(value);

      if (value && value.length == 11) {
        if (!this.validateCpf(value)) {
          this.showMyCpfError = true;
        } else {
          this.showMyCpfError = false;

          const payload: Contact = {
            contact_id: this.contact.id!,
            cpf: this.thirdForm.get('myCpf')!.value!,
          }

          this.formService.updateContactCpf(payload)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
              next: () => this.showMyCpfErrorAlreadyExist = false,
              error: (error) => {
                if (error.status == 403 && error.error.code == "cpf_or_cnpj_already_exist") {
                  this.showMyCpfErrorAlreadyExist = true;
                }
              }
            });
        }
      }
    });

    this.thirdForm.get('cpf')!.valueChanges.subscribe((value: any) => {
      this.showCpfError = false;
      this.showCpfEqualError = false;

      if (value && value.length == 11) {
        if (!this.validateCpf(value)) {
          this.showCpfError = true;
        } else {
          this.showCpfError = false;
        }
      }
    });

    this.thirdForm.get('cnpj')!.valueChanges.subscribe((value: any) => {
      this.showCnpjError = false;

      if (value && value.length === 14) {
        if (!this.validateCnpj(value)) {
          this.showCnpjError = true;
        } else {
          this.showCnpjError = false;
        }
      }
    });
  }

  validateDocumentsInputs() {
    let cpfVal = this.thirdForm.get('cpf')!.value;
    let myCpfVal = this.thirdForm.get('myCpf')!.value;
    let cnpjVal = this.thirdForm.get('cnpj')!.value;
    const nextButton = this.thirdSubmitButton.nativeElement;

    if (this.thirdForm.get('checkbox1')!.value) {
      if (!this.thirdForm.get('checkbox3')!.value) {
        if (this.thirdForm.get('checkbox1')!.value && this.validateCpf(cpfVal!) && this.validateCpf(myCpfVal!) && this.thirdForm.get('checkbox2')!.value && this.validateCnpj(cnpjVal!)) {
          nextButton.removeAttribute('disabled');
        } else if (this.thirdForm.get('checkbox1')!.value && this.validateCpf(cpfVal!) && this.validateCpf(myCpfVal!) && !this.thirdForm.get('checkbox2')!.value) {
          nextButton.removeAttribute('disabled');
        } else {
          nextButton.setAttribute('disabled', 'true');
        }
      } else {
        if (this.thirdForm.get('checkbox1')!.value && this.validateCpf(myCpfVal!) && this.thirdForm.get('checkbox2')!.value && this.validateCpf(cpfVal!) && this.validateCnpj(cnpjVal!)) {
          nextButton.removeAttribute('disabled');
        } else if (this.thirdForm.get('checkbox1')!.value && !this.validateCpf(myCpfVal!) && this.thirdForm.get('checkbox2')!.value && this.validateCpf(cpfVal!)) {
          nextButton.removeAttribute('disabled');
        } else if (this.thirdForm.get('checkbox1')!.value && this.validateCpf(myCpfVal!) && !this.thirdForm.get('checkbox2')!.value) {
          nextButton.removeAttribute('disabled');
        } else {
          nextButton.setAttribute('disabled', 'true');
        }
      }
    } else if (!this.thirdForm.get('checkbox1')!.value && this.thirdForm.get('checkbox2')!.value && this.validateCpf(cpfVal!)) {
      nextButton.removeAttribute('disabled');
    } else if (!this.thirdForm.get('checkbox1')!.value && this.thirdForm.get('checkbox2')!.value && !this.validateCpf(cpfVal!)) {
      nextButton.setAttribute('disabled', 'true');
    }

    if (!this.thirdForm.get('checkbox1')!.value && !this.thirdForm.get('checkbox2')!.value) {
      nextButton.setAttribute('disabled', 'true');
    }

    if (this.showMyCpfErrorAlreadyExist) nextButton.setAttribute('disabled', 'true');
  }

  validateCpf(cpf: string): boolean {
    return cpfCnpjValidator.cpf.isValid(cpf);
  }

  validateCnpj(cnpj: string): boolean {
    return cpfCnpjValidator.cnpj.isValid(cnpj);
  }

  onSubmit() {
    this.showCpfError = false;
    this.showCnpjError = false;

    const cpf: string | null = this.thirdForm.get('cpf')!.value;
    const myCpf: string | null = this.thirdForm.get('myCpf')!.value;
    const cnpj: string | null = this.thirdForm.get('cnpj')!.value;

    if (this.contactAlreadyExist && this.incompleteDeal) {
      this.checkToNewDeal(cpf, cnpj);
    } else {
      if (!this.thirdForm.get('checkbox1')!.value! && this.thirdForm.get('checkbox2')!.value!) {
        if (cnpj!.length == 14) {
          if (!this.validateCnpj(cnpj!)) { this.showCnpjError = true; return }
          const payload: Deal = {
            deal_name: `${this.contact.firstname} ${this.contact.lastname} - CNPJ: ${cnpj}`,
            deal_id: this.deal.id,
            natureza_juridica: "Pessoa Jurídica",
            cnpj: this.thirdForm.get('cnpj')!.value!,
            contact_id: this.contact.id!
          }
          this.updateExistedDeal(payload);
        } else {
          this.showCnpjError = true;
        }
      } else {
        if (this.thirdForm.get('checkbox1')!.value!) {
          this.showCpfEqualError = false;
          this.showMyCpfError = false;
          this.showCpfError = false;

          if (!this.thirdForm.get('checkbox3')!.value! && cpf! == myCpf!) {
            this.showCpfEqualError = true;
            return;
          } else if (!this.thirdForm.get('checkbox3')!.value! && cpf! != myCpf!) {
            if (!this.validateCpf(cpf!)) this.showCpfError = true;
            if (!this.validateCpf(myCpf!)) this.showMyCpfError = true;
            if (this.showCpfError || this.showMyCpfError) return;

            if (cpf!.length == 11) {
              const payload: Deal = {
                deal_name: `${this.contact.firstname} ${this.contact.lastname} - CPF: ${cpf}`,
                deal_id: this.deal.id,
                natureza_juridica: "Pessoa Física",
                cpf: this.thirdForm.get('cpf')!.value!,
                contact_id: this.contact.id!
              }
              this.updateExistedDeal(payload);
            } else {
              this.showCpfError = true;
              return;
            }

            if (myCpf!.length < 11) {
              this.showMyCpfError = true;
              return;
            }

          } else if (this.thirdForm.get('checkbox3')!.value!) {
            if (myCpf!.length == 11) {
              if (!this.validateCpf(cpf!)) this.showCpfError = true;
              if (!this.validateCpf(myCpf!)) this.showMyCpfError = true;
              if (this.showCpfError || this.showMyCpfError) return;

              const payload: Deal = {
                deal_name: `${this.contact.firstname} ${this.contact.lastname} - CPF: ${cpf}`,
                deal_id: this.deal.id,
                natureza_juridica: "Pessoa Física",
                cpf: this.thirdForm.get('cpf')!.value!,
                contact_id: this.contact.id!
              }
              this.updateExistedDeal(payload);
            } else {
              this.showCpfError = true;
            }
          }
        }

        if (this.thirdForm.get('checkbox2')!.value!) {

          if (this.urlParams.interClickRef) delete this.urlParams.interClickRef;
          if (this.urlParams.hs_facebook_click_id) delete this.urlParams.hs_facebook_click_id;
          if (this.urlParams.hs_google_click_id) delete this.urlParams.hs_google_click_id;
          if (this.urlParams.vantagens) delete this.urlParams.vantagens;
          if (this.urlParams._gl) delete this.urlParams._gl;
          else if (this.urlParams.gtm_debug) delete this.urlParams.gtm_debug;

          if (cnpj!.length == 14) {
            if (!this.validateCnpj(cnpj!)) { this.showCnpjError = true; return }
            const payload: Deal = {
              deal_name: `${this.contact.firstname} ${this.contact.lastname} - CNPJ: ${cnpj}`,
              cnpj: this.thirdForm.get('cnpj')!.value!,
              contact_id: this.contact.id!,
              deal_stage: "1777306",
              ...this.urlParams
            }

            this.formService.createNewDeal(payload).pipe(
              takeUntilDestroyed(this.destroyRef)
            ).subscribe({
              next: (response: any) => {
              }
            })
          } else {
            this.showCnpjError = true;
          }
        }
      }
    }
  }

  checkToNewDeal(cpf: string | null, cnpj: string | null) {
    if (this.thirdForm.get('checkbox1')!.value!) {
      if (cpf!.length == 11) {
        if (!this.validateCpf(cpf!)) { this.showCpfError = true; return }
        const payload: Deal = {
          deal_name: `${this.contact.firstname} ${this.contact.lastname} - CPF: ${cpf}`,
          cpf: this.thirdForm.get('cpf')!.value!,
          contact_id: this.contact.id!,
          deal_stage: "1777306",
          deal_id: this.contact?.deal?.id
        }
        this.updateExistedDeal(payload)
      } else {
        this.showCpfError = true;
      }
    }
    if (this.thirdForm.get('checkbox2')!.value!) {
      if (cnpj!.length == 14) {
        if (!this.validateCnpj(cnpj!)) { this.showCnpjError = true; return }
        const payload: Deal = {
          deal_name: `${this.contact.firstname} ${this.contact.lastname} - CNPJ: ${cnpj}`,
          cnpj: this.thirdForm.get('cnpj')!.value!,
          contact_id: this.contact.id!,
          deal_stage: "1777306",
          deal_id: this.contact?.deal?.id
        }
        this.updateExistedDeal(payload)
      } else {
        this.showCnpjError = true;
      }
    }
  }

  createNewDeal(payload: Deal) {
    this.formService.createNewDeal(payload).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: (response: any) => {
        this.toastNotificationService.dismiss("55D5D1D8D1F8")
        if (this.cpfNotFound) {
          this.nextStep4.next(response);
        } else {
          this.nextStep.next(response.deal);
        }
      },
      error: (error) => {
        if (error.status == 403 && (error.error.code == "cpf_or_cnpj_already_exist")) {
          this.nextStep.next(error.error);
        }
      }
    })
  }

  updateExistedDeal(payload: Deal) {
    if (this.cpfNotFound) {
      payload.deal_stage = "3521715";
    }
    this.formService.updateDeal(payload).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: (response: any) => {
        this.nextStep.next(response.deal);
        this.nextStep4.next(response);
        this.toastNotificationService.dismiss("55D5D1D8D1F8")
      },
      error: (error) => {
        if (error.status == 403 && (error.error.code == "cpf_or_cnpj_already_exist")) {
          this.nextStep.next(error.error);
        }
      }
    });
  }

  getTitle() {
    if (this.cpfNotFound && this.contact)
      return `${this.contact.firstname}, não encontramos o CPF fornecido...`;
    else if (this.cpfNotFound)
      return "Selecione aonde deseja economizar:";
    else
      return "Onde você quer economizar?";
  }

  openToastNotification(duration = 0) {
    setTimeout(() => this.toastNotificationService.showTemplate({
      id: this.idChatNotification,
      type: 'info',
      duration: 0,
      borderStyle:'left',
      template: this.customToast
    }), duration);
  }

  openChatWpp() {
    window.open("https://wa.me//31984476424/?text=Ol%C3%A1%21%20Quero%20falar%20com%20um%20consultor%20para%20economizar%20com%20a%20Bulbe.")
  }

  ngOnDestroy(): void {
    this.toastNotificationService.dismiss("55D5D1D8D1F8")
  }

  openModal() {
    this.modalService.open();
  }
}
