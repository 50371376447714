import { Component, DestroyRef, EventEmitter, HostListener, Input, Output, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Contact, FormService, SmsCode, ValidateSms } from 'src/app/services/form.service';
import { ViewIframeService } from 'src/app/services/view-iframe/view-iframe.service';
import { ThirdFormComponent } from '../third-form/third-form.component';

@Component({
  selector: 'app-second-form',
  templateUrl: './second-form.component.html',
  styleUrls: ['./second-form.component.scss']
})
export class SecondFormComponent {
  @ViewChild(ThirdFormComponent) thirdFormComponent!: ThirdFormComponent;

  @Input() contact!: Contact;
  @Input() contactAlreadyExist!: boolean;
  @Input() phone!: string;
  @Output() nextStep = new EventEmitter<Contact>();
  @Output() previousStep = new EventEmitter<any>();
  showError1: boolean = false;
  showError2: boolean = false;
  countdown: number = 30;
  interval: any;
  screenWidth!: number;
  viewIsIframe: boolean = false;
  @Output() activeChat = new EventEmitter();

  secondForm = new FormGroup({
    code1: new FormControl(null, [Validators.required, Validators.pattern(/\-?\d*\.?\d{1,2}/)]),
    code2: new FormControl(null, [Validators.required, Validators.pattern(/\-?\d*\.?\d{1,2}/)]),
    code3: new FormControl(null, [Validators.required, Validators.pattern(/\-?\d*\.?\d{1,2}/)]),
    code4: new FormControl(null, [Validators.required, Validators.pattern(/\-?\d*\.?\d{1,2}/)]),
    code5: new FormControl(null, [Validators.required, Validators.pattern(/\-?\d*\.?\d{1,2}/)]),
    code6: new FormControl(null, [Validators.required, Validators.pattern(/\-?\d*\.?\d{1,2}/)])
  });

  private destroyRef = inject(DestroyRef);

  constructor(private formService: FormService, private viewIframeService: ViewIframeService) {
    this.viewIframeService.viewIsIframe$
      .pipe(takeUntilDestroyed())
      .subscribe((value) => {
        this.viewIsIframe = value;
      })
  }

  startCounter() {
    this.interval = setInterval(() => {
      this.countdown--;
      if (this.countdown == 0) {
        clearInterval(this.interval)
      }
    }, 1000)
  }

  onDigitInput(event: any, id: string) {
    if (event.code === 'Backspace' || event.code === 'Tab' || event.code === 'Shift' || event.code === 'ControlLeft' || event.code === 'KeyA' || event.code === 'KeyV') {
      return
    }
    if (id == '') return
    return (document.querySelector(`#${id}`)! as HTMLElement).focus();

  }

  onSubmit() {
    this.showError1 = false;
    this.showError2 = false;

    let code = `${this.secondForm.get('code1')!.value}${this.secondForm.get('code2')!.value}${this.secondForm.get('code3')!.value}${this.secondForm.get('code4')!.value}${this.secondForm.get('code5')!.value}${this.secondForm.get('code6')!.value}`;

    let payload: ValidateSms = {
      contact_id: this.contact.id!,
      sms_code: code
    }

    this.formService.validateSmsCode(payload).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: (response: any) => {
        this.getUser();
      },
      error: (error => {
        // Montar o erro aqui
        if (error.status === 403 && error.error.code == 'code_not_valid') {
          this.showError1 = true;
        } else if (error.status === 403 && error.error.code == 'code_not_equal') {
          this.showError2 = true;
        }
      })

    })

  }

  resendCode() {
    this.secondForm.reset();
    let payload: SmsCode = {
      contact_id: this.contact.id!,
      resend: true,
      phone: `+55${this.phone}`
    }
    this.formService.sendSmsCode(payload).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: (response: any) => {;
        this.countdown = 60;
        this.startCounter();
      }
    })
  }

  getUser() {
    this.formService.getUser(this.contact.email!, "email").pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: (response: any) => {
        this.activeChatClick()
        this.nextStep.next(response.contact);
      }
    })
  }

  back() {
    this.previousStep.next('');
    clearInterval(this.interval);
    this.countdown = 30;
  }

  returnIframeView() {
    return window.self !== window.top;
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData('text');
    if (pastedText.length == 6) {
      this.secondForm.get('code1')?.patchValue(pastedText[0])
      this.secondForm.get('code2')?.patchValue(pastedText[1])
      this.secondForm.get('code3')?.patchValue(pastedText[2])
      this.secondForm.get('code4')?.patchValue(pastedText[3])
      this.secondForm.get('code5')?.patchValue(pastedText[4])
      this.secondForm.get('code6')?.patchValue(pastedText[5])
    }
  }

  activeChatClick(){
    this.activeChat.emit()
  }
}
