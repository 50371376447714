<div class="card">
  <div class="wrapper-section">
    <app-title title="Política de Privacidade"></app-title>

    <app-wrapper-term-policy>
      <h3>Title</h3>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium nemo quis, asperiores expedita quae sint,
        quas distinctio ullam quibusdam saepe adipisci velit officia obcaecati placeat corrupti consequuntur! Doloribus,
        debitis dolores!
      </p>

      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium nemo quis, asperiores expedita quae sint,
        quas distinctio ullam quibusdam saepe adipisci velit officia obcaecati placeat corrupti consequuntur! Doloribus,
        debitis dolores!
      </p>

      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium nemo quis, asperiores expedita quae sint,
        quas distinctio ullam quibusdam saepe adipisci velit officia obcaecati placeat corrupti consequuntur! Doloribus,
        debitis dolores!
      </p>

      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium nemo quis, asperiores expedita quae sint,
        quas distinctio ullam quibusdam saepe adipisci velit officia obcaecati placeat corrupti consequuntur! Doloribus,
        debitis dolores!
      </p>

      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium nemo quis, asperiores expedita quae sint,
        quas distinctio ullam quibusdam saepe adipisci velit officia obcaecati placeat corrupti consequuntur! Doloribus,
        debitis dolores!
      </p>

      <h4>SubTitle</h4>


      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium nemo quis, asperiores expedita quae sint,
        quas distinctio ullam quibusdam saepe adipisci velit officia obcaecati placeat corrupti consequuntur! Doloribus,
        debitis dolores!
      </p>

      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium nemo quis, asperiores expedita quae sint,
        quas distinctio ullam quibusdam saepe adipisci velit officia obcaecati placeat corrupti consequuntur! Doloribus,
        debitis dolores!
      </p>
    </app-wrapper-term-policy>
  </div>

  <div class="btn outline lg">
    <button (click)="backToHome()">
      <app-icons name="arrow-left" color="#3E4D5B"></app-icons>
      Voltar
    </button>
  </div>
</div>
