<div class="card">
  <div class="wrapper-section">
    <app-title title="Adesão realizado com sucesso!"></app-title>

    <div class="wrapper-steps">
      <div class="wrapper-icons">
        <div class="group-icons active">
          <app-icons name="list" color="#26D07C"></app-icons>
          <p>Adesão</p>
        </div>

        <div class="group-icons">
          <app-icons name="search" color="#E1E6EA"></app-icons>
          <p>Análise</p>
        </div>

        <div class="group-icons">
          <app-icons name="cogs" color="#E1E6EA"></app-icons>
          <p>Conexão</p>
        </div>

        <div class="group-icons">
          <app-icons name="bolt" color="#E1E6EA"></app-icons>
          <p>Fatura Bulbe</p>
        </div>
      </div>

      <div class="wrapper-status">
        <div class="status-progress">
          <span>1/4</span>
        </div>
        <div class="wrapper-text">
          <p class="title">Adesão</p>
          <p class="subtitle">Próximo: Análise</p>
        </div>
      </div>

      <app-progress-bar class="progress-bar" [valueProgress]="progress" color="green" [size]="mdSize">
      </app-progress-bar>
    </div>

    <p class="text">Atualizaremos você por e-mail e WhatsApp.</p>

    <div class="wrapper-text-indique-mobile">
      <h4>Indique, ganhe R$50
        e presenteie com R$50.</h4>
      <a href="../../assets/regulamento.pdf" download="Regulamento.pdf">*Confira regulamento no site.</a>
    </div>

    <div class="wrapper-cards-header">
      <div class="text-content">
        <p>Campanha por <strong>tempo limitado!</strong></p>
        <app-icons name="time" color="#08068D"></app-icons>
      </div>
    </div>

    <div class="wrapper-cards">
      <div class="text-indique">
        <img class="img-desconto" src="../../assets/images/indique-ganhe.svg" alt="Ganhe R$50">
        <p>
          de desconto adicional e
          presenteie um(a) amigo(a)
          <strong>com R$50 na Fatura Bulbe</strong>
        </p>
      </div>

      <div class="wrapper-cards-section">
        <div class="wrapper-cards-section-header">
          <p>Indique a Bulbe para sua família
            e amigos com seu link exclusivo e
            garanta <strong> R$50 de desconto</strong>
            <strong>adicional</strong> a cada adesão válida.
          </p>

          <div class="divisor"></div>

          <p>
            <strong>E mais: seu indicado também ganha R$50</strong>
            na primeira Fatura Bulbe.
          </p>


        </div>

        <div class="wrapper-cards-section-footer">
          <button class="btn sm" (click)="openWppLink()">
            Indicar por WhatsApp
            <app-icons name="shared" color="#08068D"></app-icons>
          </button>
          <div class="wrapper-cards-footer-img">
            <a href="../../assets/regulamento.pdf" download="Regulamento.pdf">*Confira regulamento no site.</a>
            <img src="../../assets/images/logo-bulbe-branca.svg" alt="Logo Bulbe Energia">
          </div>
        </div>
      </div>
    </div>

    <div class="wrapper-btn-indicar">
      <button class="btn sm btn-whatsapp" (click)="openWppLink()">
        Indicar por WhatsApp
        <app-icons name="shared" color="#08068D"></app-icons>
      </button>
    </div>
  </div>
</div>
