<div class="card" [ngClass]="{'iframe': viewIsIframe}">
  <div class="wrapper-form">
    <app-title title="Informe seus dados"></app-title>

    <form [formGroup]="firstForm" class="firstForm">
      <div class="content-form-inputs">
        <div class="group-form">
          <label for="name" class="form-label">Nome Completo <span class="prop-required">*</span></label>
          <input
            type="name"
            maxlength="50"
            class="input lg"
            id="name"
            formControlName="name"
            placeholder="Nome" />
        </div>

        <div class="group-form">
          <label for="phone" class="form-label">Número de celular <span class="prop-required">*</span> </label>
          <input
            type="phone"
            maxlength="15"
            class="input lg"
            id="phone"
            formControlName="phone"
            placeholder="(00) 90000-0000"
            mask="(00) 00000-0000" />
            <p *ngIf="(firstForm.get('phone')!.invalid && (firstForm.get('phone')!.dirty)) && firstForm.get('phone')!.errors!['invalidPhone']" class="error-message sm">
              O número de telefone celular não é válido.
            </p>
        </div>

        <div class="group-form">
          <label for="email" class="form-label">E-mail <span class="prop-required">*</span> </label>
          <input
            class="input lg"
            type="email"
            maxlength="50"
            id="email"
            formControlName="email"
            placeholder="nome@email.com" />

          <p *ngIf="urlParams?.vantagens" class="email-alert">
            O e-mail deve ser o mesmo cadastrado no Clube de Benefícios.
          </p>
        </div>
      </div>

      <div class="group-form checkbox">
        <mat-checkbox formControlName="checkbox" id="checkbox">
          Li e concordo com a&nbsp;
        </mat-checkbox>
          <a
            href="https://8488319.fs1.hubspotusercontent-na1.net/hubfs/8488319/Termos%20Bulbe%20Energia/Pol%C3%ADtica%20de%20Privacidade%20Bulbe%20Energia%2007.02.2024.pdf"
            target="_alt">
            <span>Política de Privacidade.</span>
          </a>
      </div>
    </form>
  </div>

  <div class="wrapper-btn">
    <button
      class="btn primary lg"
      [disabled]="firstForm.invalid || !firstForm.get('checkbox')!.value"
      (click)="onSubmit()">
      <span *ngIf="isLpLivelo">Economizar agora</span>
      <span *ngIf="!isLpLivelo">Próximo</span>
      <app-icons name="arrow-right" color="#FFF"></app-icons>
    </button>
  </div>
</div>
