import { LoaderService } from './../../../shared/loader/loader.service';
import { Component, DestroyRef, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CompressFileService } from 'src/app/services/compress-file/compress-file.service';
import { ImageInvoice } from 'src/app/services/upload-image/upload-image.models';
import { UploadImageService } from 'src/app/services/upload-image/upload-image.service';
import { ViewIframeService } from 'src/app/services/view-iframe/view-iframe.service';
import { formatFileSize } from 'src/app/utils/format';

export interface ClickSendImageInvoiceProps {
  formatImage: string,
  base64:  string | ArrayBuffer | null,
}

interface ButtonProps {
  label: string,
  nameIcon?: string,
  colorIcon?: string
}
@Component({
  selector: 'app-upload-invoice',
  templateUrl: './upload-invoice.component.html',
  styleUrls: ['./upload-invoice.component.scss']
})
export class UploadInvoiceComponent {
  @Output() clickSendImageInvoice: EventEmitter<ClickSendImageInvoiceProps> = new EventEmitter<ClickSendImageInvoiceProps>();
  @Output() clickBackStage: EventEmitter<void> = new EventEmitter<void>();
  @Output() emitUrlImage: EventEmitter<string | ArrayBuffer | null> = new EventEmitter<string | ArrayBuffer | null>();
  @Input() labelBtnCancelUpload: string = "Cancelar";
  @Input() configBtnPrimary: ButtonProps = { label: ""};
  @Input() configBtnOutline: ButtonProps = { label: ""};

  viewIsIframe: boolean = false;
  buttonIsDisabled: boolean = true;
  sendImage: boolean = false;
  isFormatImage: boolean = false;
  currentImageZoom: string | ArrayBuffer | null = "";
  imageInvoice: ImageInvoice = { nameImage: "", formatImage: "", base64: "", size: 0 };
  errorUploadImage: string = "";

  private destroyRef = inject(DestroyRef);

  constructor(
    private viewIframeService: ViewIframeService,
    private uploadImageService: UploadImageService,
    private compressFileService: CompressFileService,
    private loaderService: LoaderService
  ) {
    this.viewIframeService.viewIsIframe$
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe((value) => {
      this.viewIsIframe = value;
    })
  }

  ngOnInit() {
    this.uploadImageService.imageInvoice$.subscribe((invoceImage) => {
      this.imageInvoice = invoceImage;
      this.uploadImageService.resetErroImageInvoiceSubject()
    })

    this.uploadImageService.erroImageInvoice$.subscribe((err) => {
      this.errorUploadImage = err;
    })
  }

  resetSendImageInvoice() {
    this.buttonIsDisabled = true;
    this.uploadImageService.resetImageInvoiceSubject();
    this.uploadImageService.setErroImageInvoiceSubject("")
  }

  async processFile(imageInput: HTMLInputElement) {
    const file: File = imageInput.files![0];
    const formatImage = file.type.split("/")[1];

    try {
      this.loaderService.setLoading(true);
      let base64String;
      let compressedFile: Blob;

      if (formatImage == "pdf") {
        compressedFile = await this.compressFileService.compressPdf(file);
      } else {
        compressedFile = await this.compressFileService.compressImage(file);
      }

      base64String = await this.compressFileService.blobToBase64(compressedFile);

      const maxSizeFile = 2500000; // 2.5MB

      // Limita o tamanho do arquivo em 2.5MB
      if (compressedFile.size > maxSizeFile) {
        this.uploadImageService.setErroImageInvoiceSubject(`Tamanho máximo do arquivo é de ${formatFileSize(maxSizeFile)},
          e seu arquivo tem ${formatFileSize(file.size)}`)

        this.buttonIsDisabled = true;
        return;
      }

      this.imageInvoice = {
        base64: base64String,
        formatImage: formatImage,
        nameImage: file.name,
        size: compressedFile.size,
      }

      this.uploadImageService.resetErroImageInvoiceSubject();

      this.buttonIsDisabled = false;
      this.uploadImageService.setImageInvoiceSubject({ ...this.imageInvoice, base64: base64String });

      formatImage !== "pdf" ? this.isFormatImage = true : this.isFormatImage = false;
    } catch (error) {
      this.uploadImageService.setErroImageInvoiceSubject("Erro ao enviar a imagem.")
    }
    finally {
      this.loaderService.setLoading(false);
    }
  }

  handleSendInvoiceImage() {
    this.clickSendImageInvoice.emit({
      base64: this.imageInvoice.base64,
      formatImage: this.imageInvoice.formatImage
    });
  }

  handleClickBack() {
    this.resetSendImageInvoice();
    this.clickBackStage.emit();
  }

  handleOpenModalZoomImage(path:string | ArrayBuffer | null) {
    if(this.imageInvoice.formatImage === "pdf") return;

    // TODO: Criar tratativa de resove path, para enviar somente o nome do arquivo ou o base64
    if (path?.toString().includes("base")) this.emitUrlImage.emit(path)
    else this.emitUrlImage.emit(`../${path}`)
  }
}
