import { Component } from '@angular/core';
import { ViewIframeService } from 'src/app/services/view-iframe/view-iframe.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  viewIsIframe: boolean = false;

  constructor(private viewIframeService: ViewIframeService) {
    this.viewIframeService.viewIsIframe$.subscribe((value) =>
      this.viewIsIframe = value
    );
  }
}
