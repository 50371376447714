<div class="card" *ngIf="!showUploadInvoice">
  <div class="wrapper-section">
    <app-title title="Cadastro identificado"></app-title>
    <app-subtitle>
      Identificamos que você já finalizou seu cadastro. Como podemos te ajudar?
    </app-subtitle>

    <div class="wrapper-btn">
      <button (click)="goToNextStep(1)">
        Gostaria de falar com a Bulbe
        <app-icons name="arrow-right" color="#3E4D5B"></app-icons>
      </button>
      <button (click)="goToNextStep(3)">
        Quero saber o status da minha Adesão
        <app-icons name="arrow-right" color="#3E4D5B"></app-icons>
      </button>

      <button (click)="goToUploadInvoice()">
        Acelerar análise da minha instalação
        <app-icons name="arrow-right" color="#3E4D5B"></app-icons>
      </button>
    </div>
  </div>

  <app-download-app></app-download-app>
</div>

<div class="card content-upload-invoice" *ngIf="showUploadInvoice && !sendImageInvoice">
  <app-upload-invoice
    class="upload-invoice-element"
    labelBtnCancelUpload="Voltar"
    (clickSendImageInvoice)="sendInvoiceImage($event)"
    (clickBackStage)="handleCancelUploadImageInvoice()"
    (emitUrlImage)="openModalZoomImage($event)"
    [configBtnOutline]="{
      label: 'Voltar',
      nameIcon: 'arrow-left',
      colorIcon: '#3E4D5B'
    }"
    [configBtnPrimary]="{
      label: 'Enviar',
      nameIcon: 'arrow-right',
      colorIcon: '#FFFFFF'
    }"
  />
</div>

<app-modal-zoom-image [base64]="currentImageZoom"/>


<div class="card card-success" *ngIf="showUploadInvoice && sendImageInvoice">
  <div class="section">
    <div class="wrapper-title">
      <app-icons name="check" color="#26D07C"></app-icons>
      <app-title title="Fatura Cemig enviada!"></app-title>
    </div>

    <app-subtitle>
      Agora vamos analisar a imagem para acelerar sua conexão.
    </app-subtitle>
  </div>

  <section>
    <button class="btn outline lg" (click)="handleClickBack()">Voltar</button>
  </section>
</div>
