import { Component, DestroyRef, Input, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ModalZoomImageService } from 'src/app/services/modal-zoom-image/modal-zoom-image.service';
import { ViewIframeService } from 'src/app/services/view-iframe/view-iframe.service';

@Component({
  selector: 'app-modal-zoom-image',
  templateUrl: './modal-zoom-image.component.html',
  styleUrls: ['./modal-zoom-image.component.scss']
})
export class ModalZoomImageComponent {
  @Input() base64: string | ArrayBuffer | null = "";

  showModal: boolean = false;
  isViewIframe: boolean = false;

  private destroyRef = inject(DestroyRef);

  constructor(private modalZoomImageService: ModalZoomImageService, private viewIframeService: ViewIframeService) {
    this.modalZoomImageService.showModal$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((show) =>
        this.showModal = show
    );

    this.viewIframeService.viewIsIframe$
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe((isFrame) =>
      this.isViewIframe = isFrame
    );
  }

  ngOnChanges(): void {
    this.modalZoomImageService.showModal$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((show) => this.showModal = show);
  }

  closeModal() {
    this.modalZoomImageService.close();
  }

  handleKeyDown(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.modalZoomImageService.close();
    }
  }
}
