import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

  @Output() nextStep2 = new EventEmitter<number>();
  @Input() dealCpfOrCnpjDuplicated!: boolean;

  email: string = 'atendimento@bulbe.com.br';

  openWppLink() {
    window.open("https://api.whatsapp.com/send?phone=5531984476424&text=Ol%C3%A1!%20Estou%20com%20d%C3%BAvidas%20sobre%20meu%20cadastro%20com%20a%20Bulbe");
  }

  back() {
    this.nextStep2.next(0);
  }

}
