<div class="upload-fatura">
  <div class="section">
    <app-title title="Acelere sua análise"></app-title>

    <p class="subtitle">
      Para acelerar a análise e receber R$10 de desconto adicional, <strong>envie uma foto de uma <span>Fatura Cemig</span> da instalação cadastrada.</strong>
    </p>

    <form>
      <div *ngIf="!imageInvoice.nameImage" class="wrapper-btn-upload">
        <label for="file-upload-desktop" class="btn secondary lg">
          <app-icons name="image" color="#FFF"></app-icons>
          Selecionar arquivo
          <input
            #imageInputDesktop
            id="file-upload-desktop"
            type="file"
            accept="image/*, application/pdf"
            (change)="processFile(imageInputDesktop)"
          >
        </label>

        <label for="file-upload-mobile" class="btn secondary lg" id="mobile-cam">
          <app-icons name="camera" color="#FFF"></app-icons>
          Usar câmera
          <input
            #imageInputMobile
            id="file-upload-mobile"
            type="file"
            accept="image/*, application/pdf"
            capture
            (change)="processFile(imageInputMobile)"
          >
        </label>
      </div>

      <div *ngIf="imageInvoice.nameImage" class="wrapper-image-invoice">
        <div
          class="content-image"
          [ngClass]="{'cursor': isFormatImage}"
          (click)="handleOpenModalZoomImage(imageInvoice.base64)"
        >
          <app-icons name="image-invoice-default" color="#08068D"></app-icons>
          <span>{{ imageInvoice.nameImage }}</span>
        </div>

        <button class="btn-close" (click)="resetSendImageInvoice()">
          <app-icons name="x" color="#8699AC"></app-icons>
        </button>
      </div>
    </form>

    <div class="wrapper-errors">
      <span class="message-error">{{ errorUploadImage }}</span>
    </div>


    <div class="observations" *ngIf="!imageInvoice.base64">
      <img
        src="../../../assets/images/image-fatura.png"
        alt="Exemplo da imagem fatura"
        class="image"
        (click)="handleOpenModalZoomImage('../../../assets/images/image-fatura.png')"
      >

      <div class="content-text">
        <p class="title-observations">Certifique-se que:</p>
        <ul class="list">
          <li class="item-list">A fatura está enquadrada, sem cortes na imagem.</li>
          <li class="item-list">Os textos estão legíveis</li>
          <li class="item-list">A imagem não está embaçada</li>
        </ul>
      </div>
    </div>
  </div>

  <div class="section wrapper-btn-footer">
    <button class="btn outline md" (click)="handleClickBack()">
      <app-icons
        *ngIf="configBtnOutline.nameIcon"
        [name]="configBtnOutline.nameIcon"
        [color]="configBtnOutline.colorIcon!"
      />
      {{ configBtnOutline.label }}
    </button>
    <button
      class="btn primary md"
      (click)="handleSendInvoiceImage()"
      [disabled]="buttonIsDisabled"
    >
    {{ configBtnPrimary.label }}
    <app-icons
      *ngIf="configBtnPrimary.nameIcon"
      [name]="configBtnPrimary.nameIcon"
      [color]="configBtnPrimary.colorIcon!"
    />
    </button>
  </div>
</div>
