<div
  *ngIf="showModal"
  class="modal"
  [class.iframe]="isViewIframe"
  [class.show-modal]="showModal"
  (click)="closeModal()"
  (keydown)="handleKeyDown($event)"
  tabindex="0"
>
  <div
    class="modal-card"
    [class.show-modal]="showModal"
    (click)="$event.stopPropagation()"
    (keydown)="handleKeyDown($event)"
    tabindex="1"
  >
    <button *ngIf="showBtnCloseModal" class="btn-close" (click)="closeModal()">
      <app-icons name="x" color="#536679"></app-icons>
    </button>
    <ng-content></ng-content>
  </div>
</div>

