<div class="card">
    <h1>Atendimento Bulbe</h1>
    <p>Identificamos que você já realizou o cadastro na Bulbe. Como você gostaria de prosseguir?</p>

    <div class="wpp mb-2" (click)="openWppLink()" style="cursor: pointer;">
        <div class="wpp-circle">
            <span class="fa-brands-regular" style="display:contents; font-size: 24px !important;">whatsapp</span>
        </div>
        <div>
            <p class="title">Chat</p>
            <p class="subtitle">Chame no WhatsApp!</p>
        </div>
        <div style="margin-left: auto;">
            <span class="fa-regular" style="color: #000 !important;display: contents; width: 18px !important;">external-link</span>
        </div>
    </div>
    <div class="email" [cdkCopyToClipboard]="email" style="cursor: pointer;">
        <div class="mail-circle">
            <span class="fa-regular" style="display:contents; font-size: 24px !important;">envelope</span>
        </div>
        <div>
            <p class="title">Informações</p>
            <p class="subtitle">{{email}}</p>
        </div>
        <div style="margin-left: auto;">
            <span class="fa-regular" style="color: #000 !important;display: contents; width: 18px !important;">copy</span>
        </div>
    </div>
     <div class="btn-back d-flex justify-content-left mt-auto">
        <button *ngIf="!dealCpfOrCnpjDuplicated" (click)="back()"> <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.12109 16.4844L9.36719 16.2383C9.54297 16.0625 9.54297 15.8164 9.36719 15.6406L3.10938 9.34766H16.3281C16.5391 9.34766 16.75 9.17188 16.75 8.92578V8.57422C16.75 8.36328 16.5391 8.15234 16.3281 8.15234H3.10938L9.36719 1.89453C9.54297 1.71875 9.54297 1.47266 9.36719 1.29688L9.12109 1.05078C8.94531 0.875 8.69922 0.875 8.52344 1.05078L1.10547 8.46875C0.929688 8.64453 0.929688 8.89062 1.10547 9.06641L8.52344 16.4844C8.69922 16.6602 8.94531 16.6602 9.12109 16.4844Z" fill="#3E4D5B"/>
            </svg>
            Voltar</button>
    </div>
</div>
