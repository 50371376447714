<div class="wrapper-footer">
  <div class="content">
    <p class="title">Ganhe R$ 50 para cada indicação!</p>
    <p class="subtitle">Compartilhe seu link exclusivo e aproveite!</p>

    <button (click)="handleEmit()">
      <app-icons name="whatsapp" color="#FFF"></app-icons>
      <span>Compartilhar</span>
      <app-icons name="arrow-right" color="#FFF"></app-icons>
    </button>
  </div>
</div>
