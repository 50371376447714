<div class="card-notification" [class.flex]="flex" [ngClass]="type">
  <div class="content-text">
    <p *ngIf="title" class="title">{{ title }}</p>
    <p *ngIf="subtitle" class="subtitle">{{ subtitle }}</p>
  </div>

  <div class="wrapper-button" [class.flex]="flex">
    <button *ngIf="labelBtn" (click)="handleClick()">{{ labelBtn }}</button>
  </div>
</div>
