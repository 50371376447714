<div
  *ngIf="showModal"
  class="modal"
  [class.iframe]="isViewIframe"
  [class.show-modal]="showModal"
  (click)="closeModal()"
  (keydown)="handleKeyDown($event)"
  tabindex="0"
>
  <div class="wrapper" (click)="$event.stopPropagation()">
    <img [src]="base64" alt="Zoom Image" [class.show-modal]="showModal">
  </div>

  <button *ngIf="!isViewIframe" class="btn-close" (click)="closeModal()">
    <app-icons name="x" color="#3E4D5B"></app-icons>
  </button>
</div>


