import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { ClipboardModule } from '@angular/cdk/clipboard';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CardNotificationComponent } from './components/card-notification/card-notification.component';
import { CardComponent } from './components/card/card.component';
import { ArrowComponent } from './components/icons/icons.component';
import { ModalZoomImageComponent } from './components/modal-zoom-image/modal-zoom-image.component';
import { ModalComponent } from './components/modal/modal.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { SectionAppComponent } from './components/sections/download-app/download-app.component';
import { SectionIndicationWinComponent } from './components/sections/indication-win/indication-win.component';
import { UploadInvoiceComponent } from './components/sections/upload-invoice/upload-invoice.component';
import { SubtitleComponent } from './components/subtitle/subtitle.component';
import { TitleComponent } from './components/title/title.component';
import { WrapperTermPolicyComponent } from './components/wrapper-term-policy/wrapper-term-policy.component';
import { CpfNotFoundStepperComponent } from './cpf-not-found-stepper/cpf-not-found-stepper.component';
import { FifthFormComponent } from './form-stepper/fifth-form/fifth-form.component';
import { FirstFormComponent } from './form-stepper/first-form/first-form.component';
import { FormStepperComponent } from './form-stepper/form-stepper.component';
import { FourthFormComponent } from './form-stepper/fourth-form/fourth-form.component';
import { PrivacyPolicyComponent } from './form-stepper/privacy-policy/privacy-policy.component';
import { SecondFormComponent } from './form-stepper/second-form/second-form.component';
import { ThirdFormComponent } from './form-stepper/third-form/third-form.component';
import { MultipleInstallationsStepperComponent } from './multiple-installations-stepper/multiple-installations-stepper.component';
import { SelectInstallarionsComponent } from './multiple-installations-stepper/select-installarions/select-installarions.component';
import { LoadingInterceptor } from './shared/interceptors/loading.interceptor';
import { LoaderComponent } from './shared/loader/loader.component';
import { ChooseActionComponent } from './user-already-exist-stepper/choose-action/choose-action.component';
import { ChooseNewInstallationComponent } from './user-already-exist-stepper/choose-new-installation/choose-new-installation.component';
import { ContactComponent } from './user-already-exist-stepper/contact/contact.component';
import { RequestSentComponent } from './user-already-exist-stepper/request-sent/request-sent.component';
import { UserAlreadyExistStepperComponent } from './user-already-exist-stepper/user-already-exist-stepper.component';
import { ToastNotificationContentComponent } from './components/toast-notification-content/toast-notification-content.component';

@NgModule({
  declarations: [
    AppComponent,
    FormStepperComponent,
    FirstFormComponent,
    SecondFormComponent,
    ThirdFormComponent,
    FourthFormComponent,
    FifthFormComponent,
    ChooseActionComponent,
    UserAlreadyExistStepperComponent,
    ContactComponent,
    ChooseNewInstallationComponent,
    RequestSentComponent,
    LoaderComponent,
    PrivacyPolicyComponent,
    MultipleInstallationsStepperComponent,
    SelectInstallarionsComponent,
    CpfNotFoundStepperComponent,
    CardComponent,
    TitleComponent,
    ProgressBarComponent,
    CardNotificationComponent,
    SubtitleComponent,
    WrapperTermPolicyComponent,
    SectionAppComponent,
    ArrowComponent,
    SectionIndicationWinComponent,
    ModalComponent,
    ModalZoomImageComponent,
    UploadInvoiceComponent,
    ToastNotificationContentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatStepperModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatProgressBarModule,
    MatCheckboxModule,
    NgxMaskDirective, NgxMaskPipe,
    ClipboardModule
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true
    },
    {provide: 'googleTagManagerId', useValue: "GTM-PLJH6CT5"},
    provideNgxMask()
  ]
})
export class AppModule { }
