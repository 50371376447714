import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class AbTestingService {
  private variationSubject = new BehaviorSubject<string>("");
  variation$ = this.variationSubject.asObservable();

  checkVariation() {
    // Espere até que o VWO seja totalmente carregado
    setTimeout(() => {
      if (window && window.variantVWO) {
        const variation = window.variantVWO;

        this.variationSubject.next(variation)
      }
    }, 1000); // Espere 1 segundo para garantir que o VWO seja carregado
  }
}
