import { Component, DestroyRef, EventEmitter, Input, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Contact, FormService, SmsCode } from 'src/app/services/form.service';
import { ViewIframeService } from 'src/app/services/view-iframe/view-iframe.service';

function brazilianPhoneValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid = /^(?:(?:\+|00)?(55)\s?)?(?:(?:\(?[1-9][0-9]\)?)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/.test(control.value);
    return valid ? null : { invalidPhone: { value: control.value } };
  };
}

@Component({
  selector: 'app-first-form',
  templateUrl: './first-form.component.html',
  styleUrls: ['./first-form.component.scss']
})
export class FirstFormComponent {

  @Input() urlParams: any;
  @Output() nextStep = new EventEmitter<Contact>();
  @Output() deal = new EventEmitter<any>();
  @Output() contactAlreadyExist = new EventEmitter<boolean>();
  @Output() privacyPolicy = new EventEmitter<boolean>();
  @Output() phone = new EventEmitter<string>();
  @Output() emailAndPhoneAlreadyExist = new EventEmitter<boolean>();
  contact?: Contact;
  viewIsIframe: boolean = false;
  isLpLivelo: boolean = false;

  private destroyRef = inject(DestroyRef);

  constructor(private formService: FormService,
    private viewIframeService: ViewIframeService,
    private gtmService: GoogleTagManagerService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.viewIframeService.viewIsIframe$
      .pipe(takeUntilDestroyed())
      .subscribe((value) => {
        this.viewIsIframe = value;
      })
  }

  firstForm = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
    name: new FormControl(null, Validators.required),
    phone: new FormControl(null, [Validators.required, brazilianPhoneValidator()]),
    checkbox: new FormControl(null, Validators.required)
  });

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((params) => {
        const utm_source = params["utm_source"];
        this.isLpLivelo = utm_source === "livelo"
        console.log(this.isLpLivelo)
      });
  }

  onSubmit() {
    const email = this.firstForm.get('email')!.value!;

    if (this.contact?.id) {
      this.updateUser();
    } else {
      this.createUser(email);
    }
  }

  getUser(email: string) {
    this.formService.getUser(email!, "email").pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: (response: any) => {
        if (response.contact.id) {
          this.contact = response.contact;
          this.sendSms(response.contact.id, true, response.contact);
        }
      }
    });
  }

  updateUser() {
    this.formService.updateUser(this.contact!, this.firstForm.get('phone')!.value!).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: (response: any) => {
        if (response.contact.id) {
          this.contact = response.contact;
          this.sendSms(response.contact.id, this.contact?.validacao_do_numero == "true" ? true : false, response.contact);

          this.gtmService.pushTag({
            event: 'event-user-data',
            user_data: {
              email_address: response.contact.email, // em
              phone_number: `+55${this.firstForm.get('phone')!.value!}`, // ph
              address: {
                first_name: response.contact.firstname, // fn
                last_name: response.contact.lastname, // ln
              },
            },
            contact_id: response.contact.id
          });
        }
      },
      error: (error: any) => {
        if (error.status == 403 && error.error.code == "go_to_whatsapp") {
          this.emailAndPhoneAlreadyExist.next(true);
          this.nextStep.next({});
        } else if (error.status == 403 && error.error.code == "same_phone") {
          this.sendSms(this.contact?.id!, this.contact?.validacao_do_numero == "true" ? true : false, {})
        }
      }
    })
  }

  createUser(email: string) {
    const name: string = this.firstForm.get('name')!.value!;
    let contactId: any = "";
    let firstName: string = "";
    let lastName: string = "";

    let arrayName = name.split(" ");
    if (arrayName.length > 0) {
      firstName = arrayName[0];
      arrayName.splice(0, 1);
      lastName = arrayName.join(" ");
    } else {
      firstName = arrayName[0];
    }

    let payload: Contact = {
      email: this.firstForm.get('email')!.value!,
      firstname: firstName!,
      phone: `+55${this.firstForm.get('phone')!.value!}`,
      lastname: lastName!,
      ...this.urlParams
    }
    if (payload._gl) {
      delete payload._gl
    } else if (payload.gtm_debug) {
      delete payload.gtm_debug;
    }

    if (this.urlParams?.vantagens) {
      delete payload.vantagens;
    }

    if (this.urlParams?.utm_content) {
      let utmContent = this.urlParams?.utm_content;
      let utmContentSplit = utmContent.split("_");
      if (utmContentSplit[0] == "CID") {
        contactId = utmContentSplit[1];
        let code1 = parseInt(contactId.substring(0, 2), 16);
        let code2 = parseInt(contactId.substring(2, contactId.length), 16);
        contactId = code2 - code1 - 452;

        payload = {
          ...payload,
          pf_calculadora__mgm___contactid_de_quem_indicou: contactId.toString()
        }
      }
    }


    this.formService.createUser(payload).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: (response: any) => {
        this.contact = response.contact;

        if (response.contact) {
          this.gtmService.pushTag({
            event: 'event-user-data',
            user_data: {
              email_address: response.contact.email, // em
              phone_number: `+55${this.firstForm.get('phone')!.value!}`, // ph
              address: {
                first_name: response.contact.firstname, // fn
                last_name: response.contact.lastname, // ln
              },
            },
            contact_id: response.contact.id
          });
        }

        this.createDeal(response.contact.id, response.contact);
      },
      error: (error) => {
        if (error.status == 403 && error.error.code == "go_to_whatsapp") {
          this.emailAndPhoneAlreadyExist.next(true);
          this.nextStep.next({});
        } else if (error.status == 403 && error.error.code == "user_already_exist") {
          this.getUser(email);
        }

      }
    })
  }

  createDeal(contactId: string, contact: Contact) {
    const payload = {
      contact_id: contactId,
      deal_name: this.firstForm.get('name')!.value!,
      ...this.urlParams
    }
    if (payload.interClickRef) {
      delete payload.interClickRef;
    }
    if (payload._gl) {
      delete payload._gl
    } else if (payload.gtm_debug) {
      delete payload.gtm_debug;
    }
    if (this.urlParams?.vantagens) {
      delete payload.vantagens;
    }

    if (payload.hs_facebook_click_id) delete payload.hs_facebook_click_id;
    if (payload.hs_google_click_id) delete payload.hs_google_click_id;

    this.formService.createNewDeal(payload).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: (response: any) => {
        this.deal.next(response.deal);
        this.sendSms(contactId, false, contact);
      }
    })
  }

  sendSms(contactId: string, contactAlreadyExist: boolean, contact: Contact) {
    let payload: SmsCode = {
      contact_id: contactId,
      resend: false,
      phone: this.firstForm.get('phone')!.value!
    }
    this.formService.sendSmsCode(payload).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: (response: any) => {
        this.contact = contact;
        this.phone.next(this.firstForm.get('phone')!.value!);
        this.emailAndPhoneAlreadyExist.next(false);
        this.contactAlreadyExist.next(contactAlreadyExist);
        this.nextStep.next(contact);
      }
    })
  }

  openPrivacyPolicy() {
    this.privacyPolicy.next(true);
  }
}
