import { Component, EventEmitter, Input, Output } from '@angular/core';

type CardNotification = "" | "yellow" | "green" | "blue"  | "success";

@Component({
  selector: 'app-card-notification',
  templateUrl: './card-notification.component.html',
  styleUrls: ['./card-notification.component.scss']
})
export class CardNotificationComponent {
  @Input() labelBtn: string = "";
  @Input() title: string = "";
  @Input() subtitle: string = "";
  @Input() type: CardNotification = "";
  @Input() flex: boolean = false;
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();

  handleClick() {
    this.onClick.emit();
  }
}
