import { Component, Input } from '@angular/core';
import { SizeProgressBar } from 'src/app/components/progress-bar/progress-bar.component';
import { Contact } from 'src/app/services/form.service';

@Component({
  selector: 'app-fifth-form',
  templateUrl: './fifth-form.component.html',
  styleUrls: ['./fifth-form.component.scss']
})
export class FifthFormComponent {
  @Input() contact!: Contact;

  progress: number = 22;
  mdSize: SizeProgressBar = SizeProgressBar.md;
  buttonIsDisabled: boolean = true;

  openWppLink() {
    const { id } = this.contact;
    let newId: any = parseInt(id!);
    newId = newId + 16 + 452;
    newId = `10${newId.toString(16)}`;

    window.open(`https://api.whatsapp.com/send?text=*Sabia%20que%20voc%C3%AA%20pode%20economizar%20na%20conta%20de%20luz?*%20%F0%9F%92%A1%0A%0AFiz%20meu%20cadastro%20na%20Bulbe%20Energia%20e%20agora%20tenho%20at%C3%A9%2015%25%20de%20desconto%20na%20conta%20de%20luz%20todo%20m%C3%AAs!%0A%0AAh,%20fazendo%20seu%20cadastro%20agora%20voc%C3%AA%20ganha%20*R$%2050%20de%20desconto*%20adicional%20na%20primeira%20fatura%20e%20ainda%20pode%20ganhar%20mais%20R$%2050%20de%20desconto%20indicando%20outras%20pessoas.%20Incr%C3%ADvel,%20n%C3%A9?%20%E2%98%80%EF%B8%8F%0A%0AAcesse%20esse%20link%20e%20economize%20tamb%C3%A9m:%20https%3A%2F%2Fassine.bulbeenergia.com.br%2F%23%2Fshare%2F${newId}`)
  }
}
