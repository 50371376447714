import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ImageInvoice, UploadImageProps } from './upload-image.models';

@Injectable({
  providedIn: 'root'
})
export class UploadImageService {
  private imageInvoiceSubject = new Subject<ImageInvoice>();
  imageInvoice$ = this.imageInvoiceSubject.asObservable();

  private erroImageInvoiceSubject = new Subject<string>();
  erroImageInvoice$ = this.erroImageInvoiceSubject.asObservable();

  constructor(private http: HttpClient) {}

  uploadImage(payload: UploadImageProps) {
    payload.faturaCemigBase64 = (payload.faturaCemigBase64 as string).split(",")[1];
    return this.http.post(`${environment.api_erp}/Fatura/UploadFaturaCemig`, payload);
  }

  setErroImageInvoiceSubject(error: string) {
    this.erroImageInvoiceSubject.next(error)
  }

  resetErroImageInvoiceSubject() {
    this.erroImageInvoiceSubject.next("")
  }

  setImageInvoiceSubject({ nameImage, formatImage, base64, size}: ImageInvoice) {
    this.imageInvoiceSubject.next({
      nameImage,
      formatImage,
      base64,
      size,
    })
  }

  resetImageInvoiceSubject() {
    this.imageInvoiceSubject.next({
      nameImage: "",
      formatImage: "",
      base64: "",
      size: 0,
    })
  }
}
