<div class="card">
  <div class="wrapper-section">
    <div class="wrapper-notification" *ngIf="incompleteDeal && contactAlreadyExist">
      <div class="notification">
        <span>
          Continue seu cadastro para economizar com a Bulbe.
        </span>
      </div>
    </div>

    <app-title [title]="getTitle()"></app-title>

    <form [formGroup]="thirdForm" >

      <div class="checkbox-container">
        <mat-checkbox formControlName="checkbox1" id="checkbox1">
          Na minha <b>Casa</b> ou <b>Imóvel
            alugado</b>
        </mat-checkbox>

        <mat-checkbox formControlName="checkbox2" id="checkbox2">
          Na minha <b>Empresa</b>
        </mat-checkbox>
      </div>

      <div class="section-form" *ngIf="thirdForm.get('checkbox1')!.value">
        <div class="group-form">
          <label for="myCpf">
            Meu CPF <span class="prop-required">*</span>
          </label>
          <div class="group-container">
            <div>
              <input type="myCpf" maxlength="14" class="input md my-cpf" id="myCpf"
              formControlName="myCpf" placeholder="000.000.000-00" mask="000.000.000-00">
              <p class="error-message sm" *ngIf="showMyCpfError">CPF inválido.</p>
              <p class="error-message sm" *ngIf="showMyCpfErrorAlreadyExist">CPF já foi cadastrado.</p>
            </div>
            <mat-checkbox formControlName="checkbox3" id="checkbox3" class="matcheckbox custom-checkbox" [ngClass]="{'error': showMyCpfError}">
              A conta de luz está em meu nome
            </mat-checkbox>
          </div>
        </div>

      </div>

      <div class="group-form secondy" *ngIf="thirdForm.get('checkbox1')!.value">
        <div class="wrapper-label">
          <label for="cpf" class="form-label" id="document-input-label">
            CPF do titular da conta de luz <span class="prop-required">*</span>
          </label>
          <span class="wrapper-badge" *ngIf="viewIsIframe" id="btn-info-cpf-fatura-badge">
            <app-icons class="badge" name="question" color="#A4B3C1" (click)="openModal()"></app-icons>
          </span>

        </div>
        <input type="cpf" maxlength="14" class="input md" id="cpf" formControlName="cpf"
          placeholder="000.000.000-00" mask="000.000.000-00">
        <p class="error-message sm" *ngIf="showCpfError && !thirdForm.get('checkbox3')!.value">
          CPF inválido.
        </p>
        <p class="error-message sm" *ngIf="showCpfEqualError">
          O CPF do titular está igual ao seu CPF. Caso você seja o titular da conta de luz, sinalize no checkbox acima.
        </p>
      </div>

      <div class="group-form" *ngIf="thirdForm.get('checkbox2')!.value">
        <label for="cnpj" class="form-label">
          CNPJ do titular da conta de luz
          <span class="prop-required">*</span>
        </label>
        <input type="cnpj" maxlength="18" class="input md" id="cnpj" formControlName="cnpj"
          placeholder="00.000.000/0000-00" mask="00.000.000/0000-00">
        <p class="error-message sm" *ngIf="showCnpjError">
          CNPJ inválido.
        </p>
      </div>
    </form>
  </div>

  <div class="wrapper-btn">
    <button *ngIf="!viewIsIframe" class="btn primary-link sm btn-info" (click)="openModal()" id="btn-info-cpf-fatura-link">Não sei o CPF do titular</button>
    <button #thirdSubmitButton class="btn primary lg" id="third-form-submit-button" (click)="onSubmit()" disabled>
      Próximo
      <app-icons name="arrow-right" color="#FFF"></app-icons>
    </button>
  </div>
</div>

<app-modal id="modal-cpf-fatura" [showBtnCloseModal]="true">
  <div class="wrapper-modal-cpf-fatura">
    <h3>CPF do Titular</h3>
    <img src="../../assets/images/exemplo-fatura-cemig.png" alt="">
    <p>
      Certifique-se de que você está fornecendo o CPF do titular da conta de luz durante o cadastro.
      Para isso, verifique o número do documento no cabeçalho da fatura cemig mais recente.
    </p>
  </div>
</app-modal>

<div class="wrapper-btn-help" *ngIf="showToastNotificationChat">
  <button class="btn primary xl" (click)="openToastNotification()">
    <app-icons name="?" color="#FFF"></app-icons>
  </button>
</div>

<ng-template #customToast>
  <div class="toast-notification-card-header">
    <img src="../../../assets/images/user-agent-wpp.png" alt="">
    <h3>Precisa de ajuda?</h3>
  </div>
  <div class="toast-notification-card-content">
    <p>Clique e fale com nosso time de consultores via WhatsApp.</p>
  </div>
  <div class="toast-notification-card-footer">
    <button class="btn primary xs" (click)="openChatWpp()">
      <app-icons name="whatsapp" color="#FFF"></app-icons>
      Tirar dúvidas
    </button>
  </div>
</ng-template>
