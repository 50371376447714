import { Injectable } from '@angular/core';
import { PDFDocument } from 'pdf-lib';

@Injectable({
  providedIn: 'root'
})
export class CompressFileService {

  constructor() { }

  compressPdf(file: File): Promise<Blob> {
    return new Promise((resolve, reject) => {
      file.arrayBuffer()
        .then(arrayBuffer => {
          return PDFDocument.load(arrayBuffer);
        })
        .then(pdfDoc => {
          // Manipula o PDF conforme necessário.
          pdfDoc.setTitle('');
          pdfDoc.setAuthor('');
          pdfDoc.setSubject('');
          pdfDoc.setProducer('');
          pdfDoc.setCreator('');
          pdfDoc.setKeywords([]);

          return pdfDoc.save({ useObjectStreams: true });
        })
        .then(pdfBytes => {
          const compressedBlob = new Blob([pdfBytes], { type: 'application/pdf' });
          resolve(compressedBlob);
        })
        .catch(error => {
          reject(error);
        });
    });
  }


  compressImage(file: File): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      let quality = 0;
      let divisionNumber = 1.25;

      if(file.size < 1000000) {
        quality = 0.8;
      } else if(file.size > 1000000 && file.size < 2000000) {
        quality = 0.4;
        divisionNumber = 2;
      }else if(file.size > 2000000 && file.size < 4000000) {
        quality = 0.3;
        divisionNumber = 2.5
      } else {
        quality = 0.2;
        divisionNumber = 2.5
      }

      reader.onload = () => {
        const img = new Image();

        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          if (ctx) {
            canvas.width = img.width / divisionNumber;
            canvas.height = img.height /divisionNumber;
            ctx.drawImage(img, 0, 0, img.width / divisionNumber, img.height / divisionNumber);

            canvas.toBlob((blob) => {
              if (blob) {
                resolve(blob);
              } else {
                reject(new Error('Falha ao comprimir a imagem.'));
              }
            }, file.type, quality); // 0.5 é a qualidade da compressão (0.0 - 1.0)
          } else {
            reject(new Error('Contexto de desenho é nulo.'));
          }
        };

        img.onerror = (error) => {
          reject(error);
        };

        img.src = reader.result as string;
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  }

  blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result as string);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(blob);
    });
  }
}
