import { AbTestingService } from './../../services/ab-testing/ab-testing.service';
import { Component, DestroyRef, EventEmitter, Input, Output, TemplateRef, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Contact, FormService } from 'src/app/services/form.service';
import { ToastNotificationService, ToastNotificationTypes } from 'src/app/services/toast-notification/toast-notification.service';

declare var interShoppingAffiliate: any;
@Component({
  selector: 'app-fourth-form',
  templateUrl: './fourth-form.component.html',
  styleUrls: ['./fourth-form.component.scss']
})
export class FourthFormComponent {
  @ViewChild('customToast', { static: true }) customToast!: TemplateRef<any>;

  @Input('contact') contact!: Contact;
  @Input() contactAlreadyExist!: boolean;
  @Input() incompleteDeal!: boolean;
  @Input() phone: string = "";
  @Input() showToastNotificationChat: boolean = false;
  @Output() nextStep = new EventEmitter<any>();
  @Output() contactUpdated = new EventEmitter<Contact>();

  idChatNotification: string = "55D5D1D8D1F8";
  typeToasNotificationWpp: ToastNotificationTypes = ToastNotificationTypes.INFO
  textBtn: string = "";

  fourthForm = new FormGroup({
    checkbox: new FormControl(null, Validators.required)
  });

  private destroyRef = inject(DestroyRef);

  constructor(private formService: FormService,
    private gtmService: GoogleTagManagerService,
    private router: Router,
    private toastNotificationService: ToastNotificationService,
    private abTestingService: AbTestingService
  ) {
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    });

    this.abTestingService.checkVariation();

    this.abTestingService.variation$.subscribe((variation) => {
      this.textBtn = this.getTextButtonTestAB(variation);
    });
  }

  onSubmit() {
    this.gtmService.pushTag({
      event: 'button-click',
      data: 'button-aceite',
    });

    this.gtmService.pushTag({
      event: 'event-conv-adesao',
      user_data: {
        email_address: this.contact.email, // em
        phone_number: `+55${this.phone}`, // ph
        address: {
          first_name: this.contact.firstname, // fn
          last_name: this.contact.lastname, // ln
        },
      },
      contact_id: this.contact.id
    });

    this.formService.accContract({ contact_id: this.contact.id }).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: (response: any) => {
        this.toastNotificationService.dismiss("55D5D1D8D1F8")
        if (this.contactAlreadyExist) {
          this.nextStep.next(response.contact);
        } else {
          this.nextStep.next(response.contact);
        }
      },
      complete: () => {
        this.sendRegisterOpenOrder();
      },
    })
  }

  sendRegisterOpenOrder() {
    const date: any = Date.now();
    const dateObject = new Date(date);

    dateObject.toISOString();

    interShoppingAffiliate.registerOpenOrder({
      orderRef: this.contact.id,
      currency: 'BRL', // USD or BRL
      total: 110.00,
      orderDateTime: dateObject,
    });
  }

  returnIframeView() {
    return window.self !== window.top;
  }

  openToastNotification() {
    this.toastNotificationService.showTemplate({
      id: this.idChatNotification,
      type: 'info',
      duration: 0,
      borderStyle:'left',
      template: this.customToast
    });
  }

  openChatWpp() {
    window.open("https://wa.me//31984476424/?text=Ol%C3%A1%21%20Quero%20falar%20com%20um%20consultor%20para%20economizar%20com%20a%20Bulbe.")
  }

  ngOnDestroy(): void {
    this.toastNotificationService.dismiss("55D5D1D8D1F8");
  }

  getTextButtonTestAB(variation: string) {
    return variation === "btn-termo-adesao-vb" ? "Economizar na conta de luz" : "Contratar";
  }
}
