import { Component, Input } from '@angular/core';
import { ColorProp, ColorsProgress } from 'src/app/models/colorsModel';

export enum SizeProgressBar {
  "xs" = "4px",
  "sm" = "8px",
  "md" = "12px",
  "lg" = "16px",
  "xl" = "20px",
  "xxl" = "44px"
}

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {
  @Input() valueProgress: number = 0;
  @Input() color: ColorProp = "yellow";
  @Input() size: SizeProgressBar =SizeProgressBar.lg;

  colors: ColorsProgress = {
    "yellow": "var(--color-primary-yellow-sun-500)",
    "blue": "var(--color-primary-blue-sky-500)",
    "green": "var(--color-primary-green-flora-500)",
    "red": "var(--status-color-error-600)"
  };

  ngOnChanges(): void {
    if(this.valueProgress < 0) {
      this.valueProgress = 0;
      throw new Error("Valor do progresso é menor que 0.");
    }
    else if(this.valueProgress > 100){
      this.valueProgress = 100;
      throw new Error("Valor do progresso é maior que 100.");
    }
  }
}
