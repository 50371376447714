import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ViewIframeService } from 'src/app/services/view-iframe/view-iframe.service';

@Component({
  selector: 'app-subtitle',
  templateUrl: './subtitle.component.html',
  styleUrls: ['./subtitle.component.scss']
})
export class SubtitleComponent {
  viewIsIframe: boolean = false;

  constructor(private viewIframeService: ViewIframeService) {
    this.viewIframeService.viewIsIframe$
    .pipe(takeUntilDestroyed())
    .subscribe((value) => {
      this.viewIsIframe = value;
    })
  }
}
