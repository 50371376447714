<div class="card" [ngClass]="{'iframe': viewIsIframe}">
  <div class="wrapper-section">
    <app-title title="Vamos validar seu número de celular."></app-title>

    <app-subtitle>
      Digite abaixo o código de validação enviado
      por SMS no número {{phone | mask: '(00) 00000-0000'}}.
    </app-subtitle>

    <form [formGroup]="secondForm" class="second">
      <input
        id="code1"
        (paste)="onPaste($event)"
        type="number"
        pattern="[0-9]*"
        inputmode="numeric"
        (keyup)="onDigitInput($event, 'code2')"
        maxlength="1"
        class="input lg code"
        formControlName="code1"
        placeholder="0">

      <input
        id="code2"
        type="number"
        pattern="[0-9]*"
        inputmode="numeric"
        (keyup)="onDigitInput($event, 'code3')"
        maxlength="1"
        class="input lg code"
        formControlName="code2"
        placeholder="0">

      <input
        type="number"
        pattern="[0-9]*"
        inputmode="numeric"
        (keyup)="onDigitInput($event, 'code4')"
        maxlength="1"
        class="input lg code"
        formControlName="code3"
        id="code3"
        placeholder="0">

      <input
        id="code4"
        type="number"
        pattern="[0-9]*"
        inputmode="numeric"
        (keyup)="onDigitInput($event, 'code5')"
        maxlength="1"
        class="input lg code"
        formControlName="code4"
        placeholder="0">


      <input
        id="code5"
        type="number"
        pattern="[0-9]*"
        inputmode="numeric"
        (keyup)="onDigitInput($event, 'code6')"
        maxlength="1"
        class="input lg code"
        formControlName="code5"
        placeholder="0">

      <input
        id="code6"
        type="number"
        pattern="[0-9]*"
        inputmode="numeric"
        (keyup)="onDigitInput($event, '')"
        (keyup.enter)="onSubmit()"
        maxlength="1"
        class="input lg code"
        formControlName="code6"
        placeholder="0">
    </form>

    <p *ngIf="showError1" class="error-message sm">Código expirado.</p>
    <p *ngIf="showError2" class="error-message md">Código inválido.</p>

    <!-- construir o timer aqui -->
    <p class="btn-countdown" *ngIf="countdown != 0 && countdown != -1">
      <u>Reenviar código ({{countdown}}s)
      </u>
    </p>

    <p class="btn-countdown reset" *ngIf="countdown == 0" (click)="resendCode()">
      <u>Reenviar código</u>
    </p>
  </div>

  <div class="wrapper-section">
    <button class="btn outline lg" (click)="back()">
      <app-icons name="arrow-left" color="#3E4D5B"></app-icons>
      Voltar
    </button>

    <button class="btn primary lg" [disabled]="secondForm.invalid" (click)="onSubmit()">
      Próximo
      <app-icons name="arrow-right" color="#FFF"></app-icons>
    </button>
  </div>
</div>
